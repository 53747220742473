// React
import React, { useState } from "react";
import PropTypes from "prop-types";

// Material
import { Box, Typography, MobileStepper, useTheme } from "@material-ui/core";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";

// Components

// Factories

// Screens

// Assets
import {
  ManWithTabletImage,
  OfficeDeskImage,
  WaiterImage
} from "Assets/Images";

// Third Parties
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";

// Styles
const useStyles = makeStyles(theme => {
  const slideStyles = styles => ({
    backgroundImage: `url(${styles.backgroundImage})`,
    backgroundRepeat: "no-repeat",
    backgroundColor:
      theme.palette.type === "dark"
        ? theme.palette.grey[900]
        : theme.palette.grey[50],
    backgroundSize: "cover",
    backgroundPosition: "center",
    boxShadow: "inset 0 0 0 2000px rgba(0, 0, 0, 0.8)",
    height: "100%",
    textAlign: "center",
    color: "#fff",
    textShadow: `0px 0px 9px rgba(0,0,0,${styles.shadowOpacity})`,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(5)
  });

  return {
    root: {
      height: "100%"
    },
    swipeableViews: {
      height: "100%",

      "& .react-swipeable-view-container": {
        height: "100%"
      },

      "& $cloudPosSlide": {
        ...slideStyles({
          shadowOpacity: "0.9",
          backgroundImage: WaiterImage
        }),

        "& $cloudPosText": {
          fontWeight: "bold",
          textTransform: "uppercase",
          ...(() =>
            theme.direction === "rtl"
              ? {
                  fontSize:
                    "calc(" + theme.typography.h1.fontSize + " - 3.0rem)",
                  margin: theme.spacing(1.5, 0),
                  unicodeBidi: "plaintext"
                }
              : {})()
        }
      },

      "& $deviceAndInternetSlide": {
        ...slideStyles({
          shadowOpacity: "0.7",
          backgroundImage: ManWithTabletImage
        }),

        "& $deviceAndInternetText": {
          fontWeight: "bold",
          textTransform: "uppercase",
          ...(() =>
            theme.direction === "rtl"
              ? {
                  fontSize:
                    "calc(" + theme.typography.h3.fontSize + " - 1.0rem)",
                  margin: theme.spacing(1.5, 0),
                  unicodeBidi: "plaintext"
                }
              : {})()
        }
      },

      "& $onlineOfflineSlide": {
        ...slideStyles({
          shadowOpacity: "0.7",
          backgroundImage: OfficeDeskImage
        }),

        "& $onlineOfflineText": {
          fontWeight: "bold",
          textTransform: "uppercase",
          ...(() =>
            theme.direction === "rtl"
              ? {
                  fontSize:
                    "calc(" + theme.typography.h3.fontSize + " - 1.0rem)",
                  margin: theme.spacing(1.5, 0),
                  unicodeBidi: "plaintext"
                }
              : {})()
        }
      }
    },
    stepper: {
      width: "100%",
      justifyContent: "center",
      alignItems: "center",
      flexGrow: 1,
      position: "absolute",
      bottom: "5px",
      background: "transparent",
      direction: "ltr"
    }
  };
});

// Ad-Hoc Components
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const Slides = props => {
  const { classes } = props;

  return [
    <Box className={classes.cloudPosSlide}>
      <Box display={{ sm: "none", md: "block" }}>
        <Typography className={classes.cloudPosTextPrefix} variant="h4">
          <LocalizedMessage id="auth.slider.cloudPosTextPrefix"></LocalizedMessage>
        </Typography>
        <Typography className={classes.cloudPosText} variant="h1">
          <LocalizedMessage id="auth.slider.cloudPosText"></LocalizedMessage>
        </Typography>
        <Typography className={classes.cloudPosTextSuffix} variant="h6">
          <LocalizedMessage id="auth.slider.cloudPosTextSuffix"></LocalizedMessage>
        </Typography>
      </Box>
    </Box>,
    <Box className={classes.deviceAndInternetSlide}>
      <Box display={{ sm: "none", md: "block" }}>
        <Typography className={classes.deviceAndInternetText} variant="h3">
          <LocalizedMessage id="auth.slider.deviceAndInternetText"></LocalizedMessage>
        </Typography>
        <Typography
          className={classes.deviceAndInternetTextSuffix}
          variant="h6"
        >
          <LocalizedMessage id="auth.slider.deviceAndInternetTextSuffix"></LocalizedMessage>
        </Typography>
      </Box>
    </Box>,
    <Box className={classes.onlineOfflineSlide}>
      <Box display={{ sm: "none", md: "block" }}>
        <Typography className={classes.onlineOfflineText} variant="h3">
          <LocalizedMessage id="auth.slider.onlineOfflineText"></LocalizedMessage>
        </Typography>
        <Typography className={classes.onlineOfflineTextSuffix} variant="h6">
          <LocalizedMessage id="auth.slider.onlineOfflineTextSuffix"></LocalizedMessage>
        </Typography>
      </Box>
    </Box>
  ];
};

/**
 * @name Slider
 * @summary Auth Slider Component
 * @category Screens / Auth
 * @component
 * @description
 * > Renders Images + Text Slider
 * @requires React
 * @requires useState
 * @requires Box
 * @requires Typography
 * @requires MobileStepper
 * @requires useTheme
 * @requires makeStyles
 * @requires ManWithTabletImage
 * @requires OfficeDeskImage
 * @requires WaiterImage
 * @requires SwipeableViews
 * @requires autoplay
 * @see {@link https://reactjs.org/docs/getting-started.html|React Docs}
 * @see {@link https://material-ui.com/components/box/|Material UI Box}
 * @see {@link https://material-ui.com/components/typography/|Material UI Typography}
 * @see {@link https://material-ui.com/api/mobile-stepper/|Material UI MobileStepper}
 * @see {@link https://material-ui.com/styles/api/|Material UI useTheme}
 * @see {@link module:makeStyles|Helpers / Styles}
 * @see {@link module:AssetImages|Assets / Images}
 * @see {@link https://github.com/oliviertassinari/react-swipeable-views|react-swipeable-views}
 */
const Slider = props => {
  // Props Destructing
  const { children, className, ...otherProps } = props;

  // Theme & Style Hooks
  const classes = useStyles();
  const theme = useTheme();

  // Global State Hooks

  // State Hooks
  const [activeCarouselStep, setActiveCarouselStep] = useState(0);

  // Effect Hooks

  // Other Hooks

  // Event Handlers
  const handleCaroselStepChange = step => {
    setActiveCarouselStep(step);
  };

  // Other
  const SlidesArray = Slides({ classes });

  // Component Render
  return (
    <Box className={[classes.root, className].join(" ")} {...otherProps}>
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeCarouselStep}
        onChangeIndex={handleCaroselStepChange}
        enableMouseEvents
        className={classes.swipeableViews}
        children={SlidesArray}
      />
      <MobileStepper
        variant="dots"
        steps={SlidesArray.length}
        position="static"
        activeStep={activeCarouselStep}
        className={classes.stepper}
      />
    </Box>
  );
};

Slider.propTypes = {};

export default Slider;
