// React
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

// Material
import {
  Paper,
  useTheme,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  Divider,
  Chip,
  Tooltip,
  IconButton,
  Dialog,
  CircularProgress,
  Button,
  DialogTitle,
  DialogActions,
  useMediaQuery,
} from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles"
import { LocalizedMessage } from "Helpers/Localization"
import useSkeleton from "Helpers/useSkeleton"
import useStateRef from "Helpers/useStateRef"
import {
  displaySnackbarErrors,
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
} from "Utils/displaySnackbarErrors"

// Components
import Title from "Components/Title"
import { TextField, DatePicker } from "Components/FormFields"
import Row from "Components/Row"

// Factories

// Screens

// Assets

// Third Parties
import { useSnackbar } from "notistack"

// Services
import {
  useGetAllCustomers,
  useRenewSubscription
} from "Services/Customers/"

// Styles
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
}))

// Ad-Hoc Components
const columns = [
  {
    id: "brandName",
    label: <LocalizedMessage id="customer.brandName" />,
    minWidth: 200
  },
  {
    id: "owner",
    label: <LocalizedMessage id="customer.owner" />,
    minWidth: 100,
  },
  {
    id: "ownerEmail",
    label: <LocalizedMessage id="customer.ownerEmail" />,
    minWidth: 100,
  },
  {
    id: "ownerPhone",
    label: <LocalizedMessage id="customer.ownerPhone" />,
    minWidth: 100,
  },
]

const secondaryColumns = [
  {
    id: "branchName",
    label: <LocalizedMessage id="customer.branchName" />,
    minWidth: 200
  },
  {
    id: "subscriptionStatus",
    label: <LocalizedMessage id="customer.subscriptionStatus" />,
    minWidth: 200
  },
  {
    id: "subscriptionExpiryDate",
    label: <LocalizedMessage id="customer.subscriptionExpiryDate" />,
    minWidth: 200
  },
  { id: "actions", label: "", minWidth: 100 },
]

/**
 * @name Customers
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Customers = ({
  permissions,
  setHeading,
}) => {
  // Theme & Style Hooks
  const classes = useStyles()
  const Theme = useTheme()

  // Global State Hooks

  // State Hooks
  const [searchQuery, setSearchQuery] = useState("")
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(25)
  const [rows, setRows] = useStateRef([])
  const [isFetchingRows, setIsFetchingRows] = useState(true)
  const [isFetchingCsv, setIsFetchingCsv] = useState(false)
  const [isFetchingPdf, setIsFetchingPdf] = useState(false)
  const [open, setOpen] = useState(false)
  const [selectedBranch, setSelectedBranch] = useState({})

  // Effect Hooks
  useEffect(
    () =>
      setHeading(
        <LocalizedMessage id="customer.pageTitle" key="customer.pageTitle" />
      ),
    []
  )

  useEffect(() => {
    getAllCustomers({ pageRows: rowsPerPage, pageNumber: page })
      .then((customers) => {
        const newCustomers = customers.map((customer) => createData(customer))
        setIsFetchingRows(false)
        setRows(newCustomers)
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar)
      })
  }, [rowsPerPage, page])

  // Other Hooks
  const renewSubscription = useRenewSubscription({ languageKey: "customer.renewSubscribtion" })
  const [inputSkeleton, inputRef] = useSkeleton("rect")
  const getAllCustomers = useGetAllCustomers()
  const { enqueueSnackbar } = useSnackbar()

  // Other Hooks

  // Event Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleSearchChange = (target, value) => {
    setSearchQuery(value)
    setPage(0)
  }

  const onEditClick = (branchId, subscriptionExpiryDate) => {
    setIsFetchingRows(true)
    setOpen(false)

    renewSubscription({
      id: branchId,
      subscriptionExpiryDate
    })
      .then((response) => {
        displayenqueueSnackbar(enqueueSnackbar, "edited")
        const newCustomers = response.data.data.map((customer) => createData(customer))
        setRows(newCustomers)
        setIsFetchingRows(false)
      })
      .catch((errors) => {
        const setFormErrors = () => { }
        displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors)
        setIsFetchingRows(false)
        setOpen(true)
      })
  }

  // Other
  const downSm = useMediaQuery(Theme.breakpoints.down("sm"));

  function createData(customer) {

    return {
      id: customer.brandId,
      brandName: customer.brandName,
      owner: customer.owner,
      ownerEmail: customer.ownerEmail,
      ownerPhone: customer.ownerPhone,
      data: customer.branches.map(branch => {
        let subscriptionChip;
        if (branch.subscriptionStatus === 'Valid')
          subscriptionChip = <Chip label="Valid" style={{ backgroundColor: '#2196F3', color: 'white' }} />
        else if (branch.subscriptionStatus === 'InCountingDown')
          subscriptionChip = <Chip label="In Counting Down" style={{ backgroundColor: '#FF9800', color: 'white' }} />
        else if (branch.subscriptionStatus === 'InGrace')
          subscriptionChip = <Chip label="In Grace" style={{ backgroundColor: '#F44336', color: 'white' }} />
        else if (branch.subscriptionStatus === 'Expired')
          subscriptionChip = <Chip label="Expired" style={{ backgroundColor: '#F44336', color: 'white' }} />
        return {
          id: branch.id,
          subscriptionStatus: subscriptionChip,
          branchName: branch.branchName,
          subscriptionExpiryDate: branch.subscriptionExpiryDate,
          actions: (
            <Tooltip title={<LocalizedMessage id="general.edit" />}>
              <IconButton
                aria-label="edit"
                onClick={() => {
                  setSelectedBranch(branch)
                  setOpen(true)
                }}
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
            // <DatePicker
            //   name="dateFrom"
            //   tooltip={"Date from"}
            //   helpKey="Menu.category.dateFrom"
            //   label={<LocalizedMessage id="customer.dateFrom" />}
            //   onChange={(name, value) => {
            //     console.log('value----------', value);
            //     console.log('branch', branch);

            //     if (value !== branch.subscriptionExpiryDate)
            //       onEditClick(branch.branchId, value)
            //     // if (dateFrom !== value) handleClose()
            //   }}
            //   value={branch.subscriptionExpiryDate}
            //   InputLabelProps={{
            //     shrink: true,
            //   }}
            //   margin="dense"
            //   // disabled={isSubmitting.getState()}
            //   disablePast
            // />
          )
        }
      }),
    }
  }

  // Component Render
  return (
    <Box className={classes.root}>
      <Title
        pageTitle={
          <LocalizedMessage id="customer.pageTitle" key="customer.pageTitle" />
        }
      />
      <Paper
        dir={Theme.direction}
        style={{
          padding: Theme.spacing(0, 2, 2, 2),
        }}
        elevation={2}
        square
      >
        <Box
          style={{
            position: "relative",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between ",
            }}
          >
            <Typography variant="h6" component="h2">
              <LocalizedMessage id="customer.pageTitle" />
            </Typography>
            <TextField
              label={<LocalizedMessage id="general.search" />}
              size="small"
              value={searchQuery}
              onChange={handleSearchChange}
              variant="outlined"
            />
          </Box>
          <Divider style={{ margin: "0px 0px 16px" }} />

          {/* <Box display="flex" m={2}>
            <Button
              style={{ flex: 1, padding: 16 }}
              // onClick={() => window.open(pdf)}
              disabled={isFetchingRows || isFetchingCsv}
            >
              <LocalizedMessage id="customer.downloadCsv" />
            </Button>
            <Button
              style={{ flex: 1, padding: 16 }}
              // onClick={() => window.open(pdf)}
              disabled={isFetchingRows || isFetchingPdf}
            >
              <LocalizedMessage id="customer.downloadPdf" />
            </Button>
          </Box> */}

          <Paper className={classes.root}>
            {isFetchingRows && inputSkeleton ? (
              inputSkeleton
            ) : (
              <Box ref={inputRef}>
                <TableContainer className={classes.container} >
                  <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    {rows.getState().length ? <TableBody>
                      {(searchQuery
                        ? rows
                          .getState()
                          .filter(
                            (row) =>
                              row.brandName
                                .toLowerCase()
                                .indexOf(searchQuery.toLowerCase()) != -1 ||
                              searchQuery.toLowerCase() === ""
                          )
                        : rows.getState()
                      )
                        .map((row, index) => (
                          <Row
                            key={index}
                            row={row}
                            columns={columns}
                            secondaryColumns={secondaryColumns}
                          />
                        ))
                      }
                    </TableBody> : null}
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25, 100]}
                  component="div"
                  count={-1}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Box>
            )}
          </Paper>
        </Box>
      </Paper>

      <Dialog
        className={classes.innerDialog}
        open={open}
        onClose={() => setOpen(false)}
        maxWidth={"md"}
        fullScreen={downSm}
      >
        <DialogTitle >
          <LocalizedMessage id="general.editExpiryDate" />
        </DialogTitle>
        <Divider />
        <Box padding={"16px 36px"} width={downSm ? "unset" : "540px"} minHeight="129px">
          <Box
            display="flex"
            alignItems="center">
            <Box flex={8} minWidth={0}>
              <DatePicker
                name="dateFrom"
                tooltip={"Date from"}
                helpKey="Menu.category.dateFrom"
                label={<LocalizedMessage id="customer.dateFrom" />}
                onChange={(name, value) => {
                  console.log('value----------', value);
                  console.log('branch', selectedBranch);

                  if (value !== selectedBranch.subscriptionExpiryDate)
                    onEditClick(selectedBranch.branchId, value)
                  // if (dateFrom !== value) handleClose()
                }}
                value={selectedBranch.subscriptionExpiryDate}
                InputLabelProps={{
                  shrink: true,
                }}
                margin="dense"
                // disabled={isSubmitting.getState()}
                disablePast
              />
            </Box>
          </Box>
        </Box>

        {/* <DialogActions>
          <Button
            color="primary"
            onClick={() => onEditClick(selectedBranch.branchId, value)}
          // disabled={isSubmitting}
          >
            {isSubmitting ? (
              <CircularProgress size={24} />
            ) :
              "Submit"
            }
          </Button>
        </DialogActions> */}

      </Dialog>


    </Box>
  )
}

Customers.propTypes = {
  /**
   *
   */
}

Customers.defaultProps = {
  /**
   *
   */
}

export default Customers
