// React
import React, { useState } from "react";

// Material
import { Box, Grid, Slide, Fade } from "@material-ui/core";

// Local
import { makeStyles } from "Helpers/Styles";
import { SahaabColoredLogo } from "Assets/Images";
import { SignInForm, SignInNav } from "Screens/Auth/SignIn";
import { ForgetPasswordForm } from "Screens/Auth/ForgetPassword";
import Footer from "./Footer";
import Slider from "./Slider";

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100vh",
    direction: "ltr",
    flip: false,
  },

  sliderGridItem: {
    position: "relative",
    overflow: "hidden",

    "& $sliderDiagonalOverlay": {
      position: "absolute",
      height: "100%",
      width: "100%",
      background: theme.palette.background.paper,
      clipPath: "polygon(5% 0, 100% 0, 100% 100%, 0 100%)",
      top: 0,
      left: "95%",
      zIndex: 1,
      padding: 0,
      margin: 0,
      boxShadow: theme.shadows[20],
      direction: "ltr",
      flip: false,
    },
  },

  authGridItem: {
    zIndex: 2,

    "& $authWrapper": {
      padding: theme.spacing(2, 4, 1, 2),
      display: "flex",
      flexDirection: "column",
      direction: "ltr",
      background: theme.palette.background.paper,
      alignItems: "flex-start",
      minHeight: "100%",
      overflow: "hidden",
    },
  },

  logoContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  logo: {
    maxWidth: "50%",
    maxHeight: "100px",
    marginBottom: theme.spacing(3),
  },

  paper: {
    padding: theme.spacing(4, 4, 1, 4),
    display: "flex",
    flexDirection: "column",
    direction: "ltr",
    background: theme.palette.background.paper,
  },

  footer: {
    flexShrink: 0,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(3),
    width: "100%",
  },
}));

/**
 * @name Auth
 * @summary Auth Wrapper Component
 * @category Screens / Auth
 * @component
 * @see SignIn
 * @see Slider
 * @see ForgetPassword
 * @see Footer
 * @description
 * > Wrap auth Slider, SignIn, ForgetPassword and Footer components<br />
 * > Provide the transition animation between SignIn and ForgetPassword components
 */
const Auth = () => {
  const classes = useStyles();

  const [currentComponent, setCurrentComponent] = useState("SignIn");
  const [isSignInAnimationDone, setisSignInAnimationDone] = useState(true);
  const [
    isForgetPasswordAnimationDone,
    setisForgetPasswordAnimationDone,
  ] = useState(true);

  return (
    <Grid container component="main" className={classes.root}>
      <Grid className={classes.sliderGridItem} item xs={true} sm={4} md={7}>
        <Box
          className={classes.sliderDiagonalOverlay}
          display={{ xs: "none", sm: "block" }}
        ></Box>
        <Slider />
      </Grid>
      <Grid
        item
        xs={12}
        sm={8}
        md={5}
        component={Box}
        className={classes.authGridItem}
      >
        <Box className={classes.authWrapper}>
          <Box
            className={classes.logoContainer}
            alignSelf="center"
            flexShrink={0}
          >
            <img
              src={SahaabColoredLogo}
              alt="Sahaab Logo"
              className={classes.logo}
            />
          </Box>
          <Box width={"100%"} flexGrow={1}>
            <Box
              display="block"
              width="100%"
              position="relative"
              left={0}
              top={0}
            >
              <Slide
                direction="right"
                in={
                  currentComponent === "SignIn" && isForgetPasswordAnimationDone
                }
                mountOnEnter
                unmountOnExit
                timeout={{ enter: 500, exit: 200 }}
                onEntered={() => {
                  setisSignInAnimationDone(true);
                }}
                onExited={() => {
                  setisSignInAnimationDone(true);
                }}
              >
                <Box>
                  <SignInForm>
                    <SignInNav
                      goToForgetPasswordHandler={() => {
                        setisSignInAnimationDone(false);
                        setCurrentComponent("ForgetPassword");
                      }}
                    />
                  </SignInForm>
                </Box>
              </Slide>
              <Fade
                in={
                  currentComponent === "ForgetPassword" && isSignInAnimationDone
                }
                mountOnEnter
                unmountOnExit
                timeout={{ enter: 500, exit: 200 }}
                onEntered={() => {
                  setisForgetPasswordAnimationDone(true);
                }}
                onExited={() => {
                  setisForgetPasswordAnimationDone(true);
                }}
              >
                <Box>
                  <ForgetPasswordForm
                    isForgetPasswordAnimationDone={
                      isForgetPasswordAnimationDone
                    }
                    backToLoginHandler={() => {
                      setisForgetPasswordAnimationDone(false);
                      setCurrentComponent("SignIn");
                    }}
                  ></ForgetPasswordForm>
                </Box>
              </Fade>
            </Box>
          </Box>
          <Box className={classes.footer}>
            <Footer />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default Auth;
