// React
import React, { useEffect } from "react"
import PropTypes from "prop-types"

// Material
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  Grid,
  useTheme,
} from "@material-ui/core"

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles"
import { useForm } from "Helpers/Hooks"
import { LocalizedMessage } from "Helpers/Localization"
import { useGlobalState } from "Helpers/GlobalState"

// Components
import {
  TextField,
  SelectField,
} from "Components/FormFields"

// Factories

// Screens

// Assets

// Third Parties
import Joi from "@hapi/joi"
import _ from "lodash"
import zxcvbn from "zxcvbn"

// Services

// Styles
const useStyles = makeStyles(() => ({}))

// Ad-Hoc Components
const passwordValidation = (value, helpers) => {
  if (zxcvbn(value).score < 3) {
    throw new Error("password must be a strong")
  }
  return value
}

const addValidationSchema = Joi.object({
  firstName: Joi.string().required().min(3).max(50),
  lastName: Joi.string().required().min(3).max(50),
  email: Joi.string().email({ tlds: false }).required(),
  phoneNumber: Joi.number().integer().allow(null).allow(""),
  password: Joi.string().custom(passwordValidation, "password validation"),
  roleId: Joi.string().uuid().required(),
})


const editValidationSchema = Joi.object({
  firstName: Joi.string().required().min(3).max(50),
  lastName: Joi.string().required().min(3).max(50),
  email: Joi.string().email({ tlds: false }).required(),
  phoneNumber: Joi.number().integer().allow(null).allow(""),
  password: Joi.string()
    .allow("")
    .allow(null)
    .optional(),
  roleId: Joi.string().uuid().required(),
})

/**
 * @name AccountForm
 * @summary
 * @Account
 * @component
 * @description
 * >
 */
const AccountForm = ({
  editFormData,
  onAdd,
  onSave,
  onCancel,
  mode,
  open,
  setOpen,
}) => {
  // Theme & Style Hooks
  const classes = useStyles()
  const theme = useTheme()

  // Global State Hooks
  const [roles, setRoles] = useGlobalState("sharedData.roles")
  const [permissions] = useGlobalState("userData.permissions");

  // State Hooks

  // Effect Hooks
  useEffect(() => {
    console.log("editFormData", editFormData)
    if (mode === "edit") {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setFormData(
        _.pick(editFormData, [
          "firstName",
          "lastName",
          "email",
          "phoneNumber",
          "password",
          "roleId",
        ])
      )
    } else setFormData({})
  }, [open])

  // Other Hooks
  const validationSchema =
    mode === "add" ? addValidationSchema : editValidationSchema

  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading,
  } = useForm({ validationSchema, languageKey: "account" })

  // Event Handlers
  const openAddAccountForm = (parentAccount) => {
    setOpen(true)
  }

  const onCLoseAccountForm = () => {
    setOpen(false)
    setFormData({})
    setFormErrors({})
    onCancel()
  }

  const onAddAccount = () => {
    onAdd(
      formData,
      validateForm,
      setFormErrors,
      setIsSubmiting,
      onCLoseAccountForm
    )
  }

  const onSaveAccount = () => {
    onSave(
      formData,
      validateForm,
      setFormErrors,
      setIsSubmiting,
      onCLoseAccountForm
    )
  }

  // Other
  console.log(
    `%cAccountForm %cRerender`,
    "font-weight: bold; color: #000; background-color: #FFE110;",
    ""
  )

  // Component Render
  return (
    <Dialog
      open={open}
      onClose={onCLoseAccountForm}
      scroll={"paper"}
      fullScreen={true}
      style={{ direction: theme.direction }}
    >
      <DialogTitle>
        <LocalizedMessage id="account.formTitle" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {mode === "add" ? (
            <Typography component="span">
              <LocalizedMessage id="account.addNew" />
            </Typography>
          ) : (
            <Typography component="span">
              <LocalizedMessage id="account.edit" />
              <b>{formData.firstName}</b>
            </Typography>
          )}
        </DialogContentText>
        <Grid container justify="center" xs={12} spacing={2}>
          <Grid xs={12}>
            <form className={classes.form} noValidate>
              <TextField
                required
                label={<LocalizedMessage id="account.firstName" />}
                name="firstName"
                autoComplete="firstName"
                tooltip={"Account first name"}
                helpKey="account.firstNameField"
                autoFocus
                fullWidth
                //maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.firstName}
                errors={formErrors.firstName}
                isSubmitting={isSubmitting}
                isLoading={isLoading.firstName}
              />
              <TextField
                required
                fullWidth
                label={<LocalizedMessage id="account.lastName" />}
                name="lastName"
                autoComplete="lastName"
                tooltip={"Account last name"}
                helpKey="account.lastNameField"
                //  maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.lastName}
                errors={formErrors.lastName}
                isSubmitting={isSubmitting}
                isLoading={isLoading.lastName}
              />
              <TextField
                required
                fullWidth
                label={<LocalizedMessage id="account.email" />}
                name="email"
                autoComplete="email"
                tooltip={"Account email"}
                helpKey="account.emailField"
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.email}
                errors={formErrors.email}
                isSubmitting={isSubmitting}
                isLoading={isLoading.email}
              />
              <TextField
                fullWidth
                label={<LocalizedMessage id="account.phoneNumber" />}
                name="phoneNumber"
                autoComplete="phoneNumber"
                tooltip={"Account Phone Number"}
                helpKey="account.phoneNumberField"
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.phoneNumber}
                errors={formErrors.phoneNumber}
                isSubmitting={isSubmitting}
                isLoading={isLoading.phoneNumber}
              />
              <TextField
                required={mode === "add"}
                fullWidth
                label={<LocalizedMessage id="account.password" />}
                name="password"
                type="password"
                tooltip={"Account password"}
                helpKey="account.passwordField"
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.password}
                errors={formErrors.password}
                isSubmitting={isSubmitting}
                isLoading={isLoading.password}
              />
              <SelectField
                required
                name={"roleId"}
                tooltip={"Account role ID"}
                helpKey="accounts.roleId"
                label={<LocalizedMessage id="account.roleId" />}
                onChange={onFormFieldChange}
                value={formData.roleId}
                errors={formErrors.roleId}
                isSubmitting={isSubmitting}
                isLoading={roles.isFetchingRows}
                options={roles.data.map((el) => ({ value: el.id, text: el.name }))}
                addPermission={
                  permissions.isAdmin ||
                  (permissions.role && permissions.role.includes("add"))
                }
              />

            </form>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCLoseAccountForm} color="secondary">
          <LocalizedMessage id="general.cancel" />
        </Button>
        {mode === "add" ? (
          <Button
            type="button"
            color="primary"
            disabled={isSubmitting}
            onClick={onAddAccount}
          >
            {isSubmitting ? (
              <CircularProgress size={20} />
            ) : (
              <LocalizedMessage id="account.add.submit" />
            )}
          </Button>
        ) : (
          <Button
            type="button"
            color="primary"
            disabled={isSubmitting}
            onClick={onSaveAccount}
          >
            {isSubmitting ? (
              <CircularProgress size={20} />
            ) : (
              <LocalizedMessage id="account.edit.submit" />
            )}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}

AccountForm.propTypes = {
  /**
   *
   */
}

AccountForm.defaultProps = {
  onAdd: () => { },
  onSave: () => { },
  onCancel: () => { },
  mode: "add",
  formData: {},
  open: false,
  setOpen: () => { },
}

export default AccountForm
