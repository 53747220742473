// React
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// Material
import { useTheme } from "@material-ui/core";
import red from "@material-ui/core/colors/red";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { displaySnackbarErrors } from "Utils/displaySnackbarErrors"

// Components

// Factories

// Screens
import ItemList from "./ItemList";

// Assets

// Services
import { useGetLeastSellingItems } from "Services/Dashboard/"

// Third Parties
import { useSnackbar } from "notistack"
import moment from "moment";
import { useIntl } from "react-intl"

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingLeast: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}));

// Ad-Hoc Components

/**
 * @name LeastSellingItemsList
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const LeastSellingItemsList = ({ dateFrom, dateTo, }) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const Theme = useTheme();

  // Global State Hooks

  // State Hooks
  const [leastSellingItems, setLeastSellingItems] = useState([])

  // Effect Hooks
  useEffect(() => {
    getLeastSellingItems(
      moment(dateFrom).format("YYYY-MM-DD"),
      moment(dateTo).format("YYYY-MM-DD")
    )
      .then((response) => {
        setLeastSellingItems(response)
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar)
      })
  }, [dateFrom, dateTo])

  // Other Hooks
  const getLeastSellingItems = useGetLeastSellingItems()
  const { enqueueSnackbar } = useSnackbar()

  // Event Handlers

  // Other
  const intl = useIntl()

  // Component Render
  return (
    <ItemList
      perRow={4}
      title={intl.formatMessage({ id: "dashboard.leastSellingItems" })}
      items={leastSellingItems}
    // items={[
    //   {
    //     title: "Angus Burger Meal",
    //     image: AngusBurgerMeal,
    //     price: 50,
    //     ordered: 65565654546,
    //   },
    //   {
    //     title: "Stone Oven Pizza",
    //     image: StoneOvenPizza,
    //     price: 70,
    //     ordered: 5645406,
    //   },
    //   {
    //     title: "Noddles With Red Sauce",
    //     image: NoddlesWithRedSauce,
    //     price: 20,
    //     ordered: 745046,
    //   },
    //   {
    //     title: "Salmon Fish Dish With Vegetables",
    //     image: SalmonFishDish,
    //     price: 35,
    //     ordered: 9046,
    //   },
    // ]}
    />
  );
};

LeastSellingItemsList.propTypes = {
  /**
   *
   */
};

export default LeastSellingItemsList;
