// React
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

// Material
import {
  Box,
  Grid,
  Button,
  Divider,
  Typography,
  Popover,
  useTheme,
  CircularProgress,
  Card,
  CardActions,
  CardContent,
} from "@material-ui/core"
import { DatePicker, SelectField } from "Components/FormFields"

// Globals

// Helpers
import { LocalizedMessage } from "Helpers/Localization"
import { getReportDate } from "Utils"

// Components
import Title from "Components/Title"

// Factories

// Screens
import DownloadCard from "./DownloadCard"
import OrdersVsItemsChart from "./OrdersVsItemsChart"
import IncomeCard from "./IncomeCard"
import OrdersCard from "./OrdersCard"
import DiscountsCard from "./DiscountsCard"
import CancelledOrdersCard from "./CancelledOrdersCard"
import SalesBySectionCard from "./SalesBySectionCard"
import SalesByEmployeeCard from "./SalesByEmployeeCard"
import SalesByCategoryCard from "./SalesByCategoryCard"
import TopSellingItemsList from "./TopSellingItemsList"
import LeastSellingItemsList from "./LeastSellingItemsList"

// Assets

// Third Parties
import moment from "moment"
import { useIntl } from "react-intl"
import { Link } from "react-router-dom"


// Styles

// Ad-Hoc Components

/**
 * @name Dashboard
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Dashboard = ({ setHeading, permissions, dataToShow }) => {
  // Theme & Style Hooks
  const Theme = useTheme()

  // Global State Hooks

  // State Hooks
  const { from, to } = getReportDate('thisWeek', "", "")
  const [dateFrom, setDateFrom] = useState(from)
  const [dateTo, setDateTo] = useState(to)
  const [anchorEl, setAnchorEl] = useState(null)
  const [dateDuration, setDateDuration] = useState("")

  // Effect Hooks
  useEffect(
    () =>
      setHeading(
        <LocalizedMessage id="dashboard.pageTitle" key="dashboard.pageTitle" />
      ),
    []
  )

  // Other Hooks
  const intl = useIntl()

  // Event Handlers
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOnSelectDateDuration = (name, value) => {
    if (value) {
      setDateDuration(value)
      const { from, to } = getReportDate(value, dateFrom, dateTo)
      setDateFrom(from)
      setDateTo(to)
      if (value !== dateDuration && value !== 'custom')
        handleClose()
    }
  }

  // Other
  const open = Boolean(anchorEl)
  const id = open ? "simple-popover" : undefined

  const dateDurations = [
    { text: intl.formatMessage({ id: "report.dateDuration.today" }), value: "today" },
    { text: intl.formatMessage({ id: "report.dateDuration.yesterday" }), value: "yesterday" },
    { text: intl.formatMessage({ id: "report.dateDuration.thisWeek" }), value: "thisWeek" },
    { text: intl.formatMessage({ id: "report.dateDuration.lastWeek" }), value: "lastWeek" },
    { text: intl.formatMessage({ id: "report.dateDuration.thisMonth" }), value: "thisMonth" },
    { text: intl.formatMessage({ id: "report.dateDuration.lastMonth" }), value: "lastMonth" },
    { text: intl.formatMessage({ id: "report.dateDuration.thisYear" }), value: "thisYear" },
    { text: intl.formatMessage({ id: "report.dateDuration.lastYear" }), value: "lastYear" },
    { text: intl.formatMessage({ id: "report.dateDuration.custom" }), value: "custom" },
  ]

  // Component Render
  return (
    <div>
      <Title
        pageTitle={
          <LocalizedMessage
            id="dashboard.pageTitle"
            key="dashboard.pageTitle"
          />
        }
      />

      {/* {(permissions.isAdmin || permissions.dashboard) ? */}

      <Grid container justify="center" spacing={2}>
        <Grid xs={12} sm={12} item>
          <Card style={{
            minHeight: "70vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            textAlign: "center",
            padding: 20
          }}>
            <Box>
              <Typography
                justify="center"
                variant="h2"
                component="h2"
                style={{
                  flex: 1,
                  color: "#2E56A5",
                }}
              >
                {<LocalizedMessage id={"dashboard.welcome"} />}
              </Typography>

              <Divider variant="middle" style={{ marginTop: 20, }} />
            </Box>
            <Box>
              <Typography
                style={{
                  flex: 1,
                  marginBottom: 20,
                }}
                variant="h4"
                color="textSecondary">
                {<LocalizedMessage id={"dashboard.navigate"} />}
              </Typography>


              <Box
                style={{
                  flex: 1,
                  display: "flex",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                {/* {(permissions.isAdmin || dataToShow.includes("menu")) && (
                    <Button
                      component={Link}
                      to="Menu"
                      color="primary"
                      style={{ marginRight: 10 }}
                    >
                      {<LocalizedMessage id={"dashboard.menu"} />}
                    </Button>
                  )}
                  {(permissions.isAdmin || dataToShow.includes("pos")) && (
                    <Button
                      component={Link}
                      to="POS"
                      color="primary"
                      style={{ marginRight: 10 }}
                    >
                      {<LocalizedMessage id={"dashboard.pos"} />}
                    </Button>
                  )}
                  {(permissions.isAdmin || dataToShow.includes("setup")) && (
                    <Button
                      component={Link}
                      to="Setup"
                      color="primary"
                      style={{ marginRight: 10 }}
                    >
                      {<LocalizedMessage id={"dashboard.setup"} />}
                    </Button>
                  )} 
                {dataToShow.includes("report") && (
                  <Button
                    component={Link}
                    to="Reports"
                    color="primary"
                    style={{ marginRight: 20 }}
                  >
                    <LocalizedMessage id={"dashboard.reports"} />
                  </Button>
                )} */}
              </Box>
              {permissions.isOwner && (
                <Box
                  style={{
                    marginTop: 20,
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                >
                  {/* <Button
                      component={Link}
                      to="Branches"
                      color="primary"
                      style={{ marginRight: 10 }}
                    >
                      {<LocalizedMessage id={"dashboard.branches"} />}
                    </Button> */}
                  <Button
                    component={Link}
                    to="Accounts"
                    color="primary"
                    style={{ marginRight: 20 }}
                  >
                    {<LocalizedMessage id={"account.pageTitle"} />}
                  </Button>
                  <Button
                    component={Link}
                    to="Roles"
                    color="primary"
                    style={{ marginRight: 20 }}
                  >
                    {<LocalizedMessage id={"role.pageTitle"} />}
                  </Button>
                  <Button
                    component={Link}
                    to="Customers"
                    color="primary"
                    style={{ marginRight: 20 }}
                  >
                    {<LocalizedMessage id={"customer.pageTitle"} />}
                  </Button>
                  <Button
                    component={Link}
                    to="Translation"
                    color="primary"
                    style={{ marginRight: 20 }}
                  >
                    {<LocalizedMessage id={"translation.pageTitle"} />}
                  </Button>
                  <Button
                    component={Link}
                    to="GeneralSettings"
                    color="primary"
                    style={{ marginRight: 20 }}
                  >
                    {<LocalizedMessage id={"generalSettings.pageTitle"} />}
                  </Button>
                  {/* <Button
                      component={Link}
                      to="Reports"
                      color="primary"
                      style={{ marginRight: 20 }}
                    >
                      {<LocalizedMessage id={"dashboard.reports"} />}
                    </Button> */}
                </Box>
              )}
            </Box>
          </Card>
        </Grid>
      </Grid>
    </div>
  )
}

Dashboard.propTypes = {
  /**
   *
   */
}

export default Dashboard
