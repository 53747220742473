// React
import React from "react";
import PropTypes from "prop-types";

// Material
import {
  Box,
  Typography,
  Avatar,
  Button,
  // TextField,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from "@material-ui/core";
import { LockOutlined as LockOutlinedIcon } from "@material-ui/icons";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import { useForm } from "Helpers/Hooks";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import Title from "Components/Title";
import { TextField, PasswordField } from "Components/FormFields";

// Factories

// Screens

// Assets
import { LoginVector } from "Assets/Vectors";

// Third Parties
import { useSnackbar } from "notistack";
import { useHistory } from "react-router-dom";
import Joi from "@hapi/joi";

// Services
import { useSignIn } from "Services/Auth";

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  vector: {
    width: "55%",
    alignSelf: "center",
    marginBottom: theme.spacing(1),
  },
  title: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "center",

    "& $avatar": {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

// Ad-Hoc Components
const validationSchema = Joi.object({
  email: Joi.string().email({ tlds: false }).required(),
  password: Joi.string().required(),
});

/**
 * @name SignInForm
 * @summary Sign In Form Component
 * @category Screens / Auth
 * @component
 * @see Auth
 * @see SignInNav
 * @description
 * > Renders Sign In Form and handle authentication
 */
function SignInForm({ children, className, ...otherProps }) {
  // Theme & Style Hooks
  const classes = useStyles();

  // Global State Hooks
  const [userAuth, setUserAuth] = useGlobalState("userData.auth");
  const [serverStorage, setServerStorage] = useGlobalState("userData.serverStorage");

  // State Hooks

  // Effect Hooks

  // Other Hooks
  let history = useHistory();

  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading,
    setIsLoading,
  } = useForm({ validationSchema, languageKey: "auth.signIn" });
  const signIn = useSignIn({ languageKey: "auth.signIn" });
  const { enqueueSnackbar } = useSnackbar();

  // Event Handlers
  const onSubmit = (e) => {
    const validForm = validateForm();

    if (validForm) {
      const { email, password } = formData;
      setIsSubmiting(true);
      signIn({ email, password })
        .then(({ accessToken }) => {
          enqueueSnackbar(<LocalizedMessage id="auth.signIn.success" />, {
            variant: "success",
            autoHideDuration: 5000,
          });
          setUserAuth((ua) => ({ ...ua, token: accessToken }));
          // goToSignInHandler();
          // history.replace("/portal/dashboard");
          window.location.href = window.location.origin + '/portal'
          setIsSubmiting(false);
        })
        .catch((errors) => {
          errors.forEach((e) => {
            if (e.field) {
              setFormErrors((fe) => ({
                ...fe,
                [e.field]: [
                  ...(typeof fe[e.field] === "array" ? fe[e.field] : []),
                  <LocalizedMessage id={e.languageKey} />,
                ],
              }));
            } else {
              enqueueSnackbar(<LocalizedMessage id={e.languageKey} />, {
                variant: "error",
                autoHideDuration: 3000,
              });
            }
          });
          setIsSubmiting(false);
        });
    }
  };

  // Other

  // Component Render
  return (
    <Box className={[classes.root, className].join(" ")} {...otherProps}>
      <Title pageTitle={<LocalizedMessage id="auth.signIn.pageTitle" />} />
      <img src={LoginVector} className={classes.vector} />
      <Box className={classes.title}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          <LocalizedMessage id="auth.signIn.title" />
        </Typography>
      </Box>
      <form className={classes.form} noValidate>
        <TextField
          required
          label={<LocalizedMessage id="auth.signIn.emailAddress" />}
          name="email"
          autoComplete="email"
          autoFocus
          onChange={onFormFieldChange}
          value={formData.email}
          errors={formErrors.email}
          isSubmitting={isSubmitting}
          isLoading={isLoading.email}
        />
        <PasswordField
          required
          name="password"
          label={<LocalizedMessage id="auth.signIn.password" />}
          InputProps={{
            autoComplete: "current-password",
          }}
          autoComplete="current-password"
          onChange={onFormFieldChange}
          value={formData.password}
          errors={formErrors.password}
          isSubmitting={isSubmitting}
          isLoading={isLoading.password}
        />
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" />}
          label={<LocalizedMessage id="auth.signIn.rememberMe" />}
        />
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={onSubmit}
          disabled={isSubmitting}
        >
          {isSubmitting ? (
            <CircularProgress size={20} />
          ) : (
              <LocalizedMessage id="auth.signIn.submit" />
            )}
        </Button>
        {children}
      </form>
    </Box>
  );
}

SignInForm.propTypes = {
  /**
   * Nodes to be rendered below the form
   */
  children: PropTypes.arrayOf(PropTypes.node),
};

export default SignInForm;
