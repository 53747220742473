// React
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import { Box, FormHelperText, FormControl } from "@material-ui/core";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import useSkeleton from "Helpers/useSkeleton";

// Components
import HelpIcon from "Components/HelpIcon";

// Factories

// Screens

// Assets

// Help

// Third Parties
import uuid from "uuid";
import MUIPhoneField from "material-ui-phone-number";

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  helperTextWrapper: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    flex: 1,
  },
}));

// Ad-Hoc Components

/**
 * @name TextField
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const TextField = ({
  className,
  style,
  tooltip,
  helpKey,
  helpIconProps,
  variant,
  margin,
  autoComplete,
  inputProps,
  size,
  helperText,
  maxCharacters,
  defaultValue,
  value,
  onChange,
  id,
  //   validator,
  //   onlyShowFirstError,
  isLoading,
  isSubmitting,
  hidden,
  name,
  label,
  disabled,
  errors,
  preHelperComponents,
  postHelperComponents,
  fullWidth,
  ...otherProps
}) => {
  const inputFieldID = id || uuid.v4();

  // Theme & Style Hooks
  const classes = useStyles();

  // Global State Hooks

  // State Hooks

  // const [textFieldValue, setTextFieldValue] = useState(value);

  // Effect Hooks
  useEffect(() => {
    console.log(
      `%cPhone Field %cMount -> %cName: %c${name}`,
      "font-weight: bold; color: blue",
      "",
      "font-weight: bold;",
      ""
    );
  }, []);

  useEffect(() => {
    if (typeof onChange === "function") onChange(name, value);
    console.log(
      `%cPhone Field %cValue Changed -> %cName: %c${name} - %cValue: %c${value}`,
      "font-weight: bold; color: blue",
      "",
      "font-weight: bold;",
      "",
      "font-weight: bold;",
      ""
    );
  }, [value]);

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect");
  const [helperTextSkeleton, helperTextRef] = useSkeleton("rect");

  // Event Handlers
  const onChangeHandler = (v) => {
    typeof onChange === "function" && onChange(name, v);
  };

  // Other

  console.log(
    `%cPhone Field %cRerender -> %cName: %c${name} %cCurrentValue: %c${value}`,
    "font-weight: bold; color: blue",
    "",
    "font-weight: bold;",
    "",
    "font-weight: bold;",
    ""
  );

  // Component Render
  return (
    <Box className={className} style={{ display: hidden ? "none" : "block" }}>
      <FormControl {...{ margin }} className={classes.root} style={style}>
        {isLoading && inputSkeleton ? (
          inputSkeleton
        ) : (
          <MUIPhoneField
            {...{
              ref: inputRef,
              inputProps: {
                autoComplete,
                id: inputFieldID,
                ...inputProps,
              },
              error: Boolean(errors && errors.length > 0),
              value: value,
              onChange: onChangeHandler,
              fullWidth,
              size,
              defaultValue,
              variant,
              name,
              label,
              disabled: isSubmitting || disabled,
              ...otherProps,
            }}
          />
        )}

        <HelpIcon
          tooltip={tooltip}
          helpKey={helpKey}
          modalTitle={label}
          size={size}
          isLoading={isLoading}
          isSubmitting={isSubmitting}
          {...helpIconProps}
        />
      </FormControl>

      <Box className={classes.root}>
        {(helperText || errors || preHelperComponents) && (
          <Box style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            {preHelperComponents && <Box>{preHelperComponents}</Box>}
            <FormHelperText
              error={Boolean(errors && errors.length > 0)}
              className={classes.helperTextWrapper}
            >
              <Box>
                <span className={classes.helperTextWrapper}>
                  {helperText &&
                    !errors &&
                    (isLoading && helperTextSkeleton !== undefined ? (
                      helperTextSkeleton
                    ) : (
                      <span ref={helperTextRef}>{helperText}</span>
                    ))}
                  {errors && (
                    <span style={{ display: "flex", flexDirection: "column" }}>
                      {errors.map((errorMessage, i) => (
                        <span key={i}>{errorMessage}</span>
                      ))}
                    </span>
                  )}
                </span>
              </Box>
            </FormHelperText>
            {postHelperComponents && <Box>{postHelperComponents}</Box>}
          </Box>
        )}
        <HelpIcon
          style={{ visibility: "hidden", height: 0 }}
          tooltip={tooltip}
          helpKey={helpKey}
          modalTitle={label}
          size={size}
          {...helpIconProps}
        />
      </Box>
    </Box>
  );
};

TextField.propTypes = {
  /**
   *
   */
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  //   validator: PropTypes.func,
  autoFocus: PropTypes.bool,
  tooltip: PropTypes.string,
  helpKey: PropTypes.string,
  maxCharacters: PropTypes.number,
};

TextField.defaultProps = {
  variant: "outlined",
  margin: "normal",
  //   autoComplete: "off",
  onlyShowFirstError: true,
  isLoading: false,
  autoFocus: false,
  onChange: () => {},
  fullWidth: true,
};

export default TextField;
