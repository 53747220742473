// React
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

// Material
import {
  Box,
  Typography,
  Avatar,
  Button,
  // TextField,
  InputAdornment,
  IconButton,
  LinearProgress,
  CircularProgress,
  useTheme,
} from "@material-ui/core"

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles"
import { useGlobalState } from "Helpers/GlobalState"
import { LocalizedMessage } from "Helpers/Localization"

// Components

// Factories
import structure from "Data/DesignStructure";

// Screens

// Assets
import { SahaabColoredLogo } from "Assets/Images"

// Third Parties
import { useSnackbar } from "notistack"
import { useHistory } from "react-router-dom"

// Services
import { useGetUserMe } from "Services/User"
import { useStorageGetAll } from "Services/Storage"

// Styles
const useStyles = makeStyles(() => ({}))

// Ad-Hoc Components

/**
 * @name Initializer
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Initializer = ({ children }) => {
  // Theme & Style Hooks
  const classes = useStyles()
  const theme = useTheme()

  // Global State Hooks
  const [userAuth, setUserAuth] = useGlobalState("userData.auth")
  const [appState, setAppState] = useGlobalState("global.app")
  const [serverStorageState, setServerStorageState] = useGlobalState(
    "userData.serverStorage"
  )
  const [accountState, setAccountState] = useGlobalState("userData.account")
  const [locale, setLocale] = useGlobalState("global.locale")

  const [permissions, setPermissions] = useGlobalState("userData.permissions");
  const [dataToShowInPortal, setDataToShowInPortal] = useGlobalState("userData.dataToShowInPortal");

  // State Hooks
  let history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  //   const [initializerMounted, setInitializerMounted] = useState(false);

  // Effect Hooks
  useEffect(() => {
    /** Load User Auth Data From Local Storage */
    let userStoredAuth = {}
    let storedLocale = ""
    try {
      userStoredAuth = JSON.parse(localStorage.getItem("userAuth")) || {}
      storedLocale = JSON.parse(localStorage.getItem("locale")) || "en"
    } catch (e) { }
    Object.keys(userAuth).forEach((key, i) => {
      userStoredAuth[key] = userStoredAuth[key] || userAuth[key]
    })

    setLocale(storedLocale)

    setUserAuth((ua) => ({
      ...ua,
      ...userStoredAuth,
      loaded: true,
    }))
  }, [])

  const loadUserData = () => {
    userMe()
      .then(({ serverStorage, account }) => {
        let newServerStorage = {}
        Object.keys(serverStorageState).forEach((key, i) => {
          newServerStorage[key] =
            (serverStorage.find(
              (serverStorageObj) => serverStorageObj.key === key
            ) || {})["value"] || serverStorageState[key]
        })

        let newAccount = {}
        Object.keys(accountState).forEach((key, i) => {
          newAccount[key] = account[key] || accountState[key]
        })

        /**@TODO Refactor */
        loadPermissions(newAccount)

        setServerStorageState((sss) => ({
          ...sss,
          ...newServerStorage,
        }))
        setAccountState((as) => ({
          ...as,
          ...newAccount,
        }))

        const currentWindow = window.location.pathname

        if (currentWindow === "/auth" || currentWindow === "/")
          history.replace("/portal")
        else history.replace(currentWindow)

        setAppState((as) => ({
          ...as,
          loaded: true,
        }))
      })
      .catch((errors) => {
        /** @TODO Redirect To Auth */
        console.log("errors", errors)
        errors.forEach((e) => {
          enqueueSnackbar(<LocalizedMessage id={e.languageKey} />, {
            variant: "error",
            autoHideDuration: 3000,
          })
        })
        history.replace("/auth")
        setAppState((as) => ({
          ...as,
          loaded: true,
        }))
      })
  }

  const loadPermissions = (newAccount) => {
    let keys, dataToShow;
    console.log('newAccount', newAccount);

    if (newAccount.adminPanelRole.isAdmin) {
      setPermissions({ isOwner: true, isAdmin: true });
      setDataToShowInPortal(Object.keys(structure))
    } else {
      const jsonPermissions = JSON.parse(newAccount.adminPanelRole.permissionsMatrix);
      keys = Object.keys(jsonPermissions);
      setPermissions({ ...jsonPermissions });

      if (keys)
        dataToShow = Object.entries(structure)
          .map(([parent, children]) => {
            for (let index = 0; index < keys.length; index++)
              if (keys[index] in children) return parent;
          })
          .filter((el) => el !== undefined);

      setDataToShowInPortal(dataToShow);
    }
  }

  useEffect(() => {
    if (userAuth.loaded) {
      if (userAuth.token) {
        loadUserData()
      } else {
        history.replace("/auth")
        setAppState((as) => ({
          ...as,
          loaded: true,
        }))
      }
    }
  }, [userAuth])

  // Other Hooks
  const userMe = useGetUserMe({
    languageKey: "user",
  })
  const storageGetAll = useStorageGetAll({
    languageKey: "storage",
  })

  // Event Handlers

  // Other

  // Component Render
  return !appState.loaded ? (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        height: "100vh",
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "center",
          flex: 1,
          padding: theme.spacing(2),
        }}
      >
        <img
          src={SahaabColoredLogo}
          style={{
            width: "40%",
          }}
          alt="Sahaab Logo"
        />
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
          flex: 1,
          padding: theme.spacing(2),
        }}
      >
        <Typography>
          <CircularProgress size={16} /> Loading App...{" "}
        </Typography>
      </Box>
    </Box>
  ) : (
    children
  )
}

Initializer.propTypes = {
  /**
   *
   */
}

Initializer.defaultProps = {
  /**
   *
   */
}

export default Initializer
