// React
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

// Material
import {
  Box,
  Select as MUISelectField,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  ListItemText,
  Checkbox,
  ListSubheader,
} from "@material-ui/core"
import { withStyles } from "@material-ui/styles"

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles"
import useSkeleton from "Helpers/useSkeleton"

// Components
import HelpIcon from "Components/HelpIcon"

// Factories

// Screens

// Assets

// Help

// Third Parties
import uuid from "uuid"

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    minWidth: 0,
    flexShrink: 1,
  },
  fieldWrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    minWidth: 0,
    flexShrink: 1,
  },
  field: {
    flex: 1,
    minWidth: 0,
    flexShrink: 1,
  },
  formControl: {
    flex: 1,
    minWidth: 0,
    flexShrink: 1,
  },
}))

const MUISelectFieldExtended = withStyles((theme) => ({
  root: {
    minWidth: 0,
    flexShrink: 1,
  },
  select: {
    minWidth: 0,
    flexShrink: 1,
  },
  filled: {
    minWidth: 0,
    flexShrink: 1,
  },
  outlined: {
    minWidth: 0,
    flexShrink: 1,
  },
}))(MUISelectField)

// Ad-Hoc Components

/**
 * @name SelectField
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const SelectField = ({
  className,
  style,
  tooltip,
  helpKey,
  variant,
  margin,
  autoComplete,
  helpIconProps,
  options,
  getOptionLabel,
  getOptionSelected,
  defaultValue,
  onChange,
  helperText,
  size,
  inputProps,
  isLoading,
  hidden,
  isSubmitting,
  disabled,
  setDefaultValue,
  label,
  id,
  required,
  name,
  value,
  multiple,
  validator,
  errors,
  preHelperComponents,
  postHelperComponents,
  fullWidth,
  ...otherProps
}) => {
  // const getOptionLabel = (option) => option.text;
  // const getOptionSelected = (option) => option.value;
  // console.log(value);
  let currentValue
  if (value) {
    if (multiple) {
      currentValue = value
        .map((v) => options.find((option) => getOptionSelected(option) === v))
        .filter((v) => v !== undefined)
    } else {
      currentValue = options.find(
        (option) => getOptionSelected(option) === value
      )
      currentValue = currentValue || []
    }
  } else if (defaultValue) {
    if (multiple) {
      currentValue = defaultValue
        .map((v) => options.find((option) => getOptionSelected(option) === v))
        .filter((v) => v !== undefined)
    } else {
      currentValue = options.find(
        (option) => getOptionSelected(option) === defaultValue
      )
      currentValue = currentValue || { text: "", value: "" }
    }
  } else {
    if (multiple) {
      currentValue = []
    } else {
      currentValue = { text: "", value: "" }
    }
  }

  // const currentValue = value
  //   ? multiple
  //     ? value
  //         .map((v) => options.find((option) => getOptionSelected(option) === v))
  //         .filter((v) => v !== undefined)
  //     : options.find((option) => getOptionSelected(option) === value)
  //   : multiple
  //   ? []
  //   : defaultValue
  //   ? options.find((option) => getOptionSelected(option) === defaultValue)
  //   : { text: "", value: "" };

  // Theme & Style Hooks
  const classes = useStyles()

  // Global State Hooks

  // State Hooks
  const [inputFieldID] = useState(id || uuid.v4())

  // Effect Hooks
  useEffect(() => {
    onChangeHandler({
      persist: () => {},
      target: {
        name,
        value: multiple ? currentValue.map((v) => v.text) : currentValue.text,
      },
    })
    console.log(
      `%cSelect %cField Mount -> %cName: %c${name}`,
      "font-weight: bold; color: burlywood",
      "",
      "font-weight: bold;",
      ""
    )
  }, [])

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect")
  const [helperTextSkeleton, helperTextRef] = useSkeleton("rect")

  // Event Handlers
  const onChangeHandler = (e) => {
    if (typeof onChange == "function") {
      let o
      if (multiple) {
        o = e.target.value.map((id) =>
          options.find((option) => getOptionLabel(option) === id)
        )
      } else {
        o = options.find((option) => getOptionLabel(option) === e.target.value)
      }

      e.target.value = o
        ? multiple
          ? o.map((oo) => oo.value)
          : o.value
        : multiple
        ? []
        : ""

      onChange(e.target.name, e.target.value)
    }
  }

  // Other
  console.log(
    `%cSelect %cRerender -> %cName: %c${name}`,
    "font-weight: bold; color: burlywood",
    "",
    "font-weight: bold;",
    ""
  )

  // Component Render
  return (
    <Box className={className}>
      <Box className={classes.root} style={style}>
        <FormControl
          variant={variant}
          className={classes.formControl}
          margin={margin}
          size={size}
        >
          <Box className={classes.fieldWrapper}>
            {isLoading && inputSkeleton ? (
              inputSkeleton
            ) : (
              <>
                <InputLabel
                  variant={variant}
                  id={`${inputFieldID}-label`}
                  htmlFor={inputFieldID}
                  required={required}
                >
                  {label}
                </InputLabel>
                <MUISelectFieldExtended
                  ref={inputRef}
                  labelId={`${inputFieldID}-label`}
                  // defaultValue={defaultValue && getOptionLabel(defaultValue)}
                  onChange={onChangeHandler}
                  error={Boolean(errors && errors.length > 0)}
                  inputProps={{
                    autoComplete,
                    disabled: isSubmitting || disabled,
                    id: inputFieldID,
                    ...inputProps,
                  }}
                  disabled={isSubmitting || disabled}
                  variant={variant}
                  className={classes.field}
                  id={inputFieldID}
                  required={required}
                  label={label}
                  name={name}
                  value={
                    multiple
                      ? currentValue.map((v) => v.text)
                      : currentValue.text
                  }
                  multiple={multiple}
                  renderValue={multiple && ((selected) => selected.join(", "))}
                  {...otherProps}
                >
                  {options.map((option, i) => {
                    return multiple ? (
                      <MenuItem
                        value={getOptionLabel(option)}
                        key={option.value}
                      >
                        <Checkbox
                          checked={Boolean(
                            currentValue.find((cv) => cv.value === option.value)
                          )}
                        />
                        <ListItemText primary={getOptionLabel(option)} />

                        {option.subOptions.map((subOption) => {
                          const checked = currentValue.length
                            ? currentValue
                                .map((cv) =>
                                  cv.subOptions.find(
                                    (so) => so.value === subOption.value
                                  )
                                )
                                .filter((v) => v !== undefined)
                            : undefined

                          return (
                            <MenuItem
                              value={getOptionLabel(subOption)}
                              key={subOption.value}
                            >
                              <Checkbox
                                checked={Boolean(
                                  checked
                                    ? checked.length
                                      ? checked[0]
                                      : undefined
                                    : undefined
                                )}
                              />
                              <ListItemText
                                primary={getOptionLabel(subOption)}
                              />
                            </MenuItem>
                          )
                        })}
                      </MenuItem>
                    ) : (
                      <MenuItem value={getOptionLabel(option)} key={i}>
                        {getOptionLabel(option)}
                      </MenuItem>
                    )
                  })}
                </MUISelectFieldExtended>
              </>
            )}
            <HelpIcon
              {...{
                tooltip,
                helpKey,
                size,
                isLoading,
                modalTitle: label,
                ...helpIconProps,
              }}
            />
          </Box>
          <Box className={classes.root}>
            {(helperText || errors || preHelperComponents) && (
              <Box
                style={{ display: "flex", flexDirection: "column", flex: 1 }}
              >
                {preHelperComponents && <Box>{preHelperComponents}</Box>}
                <FormHelperText
                  error={Boolean(errors && errors.length > 0)}
                  className={classes.helperTextWrapper}
                  component="div"
                  style={{
                    display: "flex",
                    flex: 1,
                  }}
                >
                  <span className={classes.helperTextWrapper}>
                    {helperText &&
                      !errors &&
                      (isLoading && helperTextSkeleton !== undefined ? (
                        helperTextSkeleton
                      ) : (
                        <span ref={helperTextRef}>{helperText}</span>
                      ))}
                    {errors && (
                      <span
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        {errors.map((errorMessage, i) => (
                          <span key={i}>{errorMessage}</span>
                        ))}
                      </span>
                    )}
                  </span>
                </FormHelperText>
                {postHelperComponents && <Box>{postHelperComponents}</Box>}
              </Box>
            )}
            <HelpIcon
              style={{ visibility: "hidden", height: 0 }}
              tooltip={tooltip}
              helpKey={helpKey}
              modalTitle={label}
              size={size}
              {...helpIconProps}
            />
          </Box>
        </FormControl>
      </Box>
    </Box>
  )
}

SelectField.propTypes = {
  /**
   *
   */
}

SelectField.defaultProps = {
  options: Array(),
  variant: "outlined",
  margin: "normal",
  autoComplete: "off",
  multiple: false,
  getOptionLabel: (option) => option.text,
  getOptionSelected: (option) => option.value,
}

export default SelectField
