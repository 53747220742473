// React
import React from "react"
import PropTypes from "prop-types"

// Material
import { Box, Button, Typography, useTheme, Tooltip } from "@material-ui/core"
import Card from "@material-ui/core/Card"
import CardActions from "@material-ui/core/CardActions"
import CardMedia from "@material-ui/core/CardMedia"
import CardContent from "@material-ui/core/CardContent"
import red from "@material-ui/core/colors/red"

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles"
import { LocalizedMessage } from "Helpers/Localization"
import { useGlobalState } from "Helpers/GlobalState"

// Components

// Factories

// Screens

// Assets

// Third Parties
import numeral from "numeral"

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
}))

// Ad-Hoc Components

/**
 * @name ItemCard
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const ItemCard = ({ item, style }) => {
  // Theme & Style Hooks
  const classes = useStyles()
  const Theme = useTheme()

  // Global State Hooks
  const [branch, setBranch] = useGlobalState("userData.branch")

  // State Hooks
  const [anchorEl, setAnchorEl] = React.useState(null)

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other
  const { name, image, ordered, price } = item
  // Component Render
  return (
    <Card style={{ ...style, display: "flex", flexDirection: "column" }}>
      <CardMedia
        image={image}
        className={classes.media}
        title={name}
        style={{
          flex: 1,
          borderBottom: `5px solid ${item.color}`,
          backgroundColor: `${item.color}`,
        }}
      ></CardMedia>
      <CardContent
        style={{ display: "flex", flexDirection: "column", flex: 1 }}
      >
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h6" component="h2">
            {name}
          </Typography>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "auto",
          }}
        >
          <Typography
            variant="subtitle2"
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <Typography variant="inherit">
              <LocalizedMessage id="item.ordered" />:
            </Typography>
            &nbsp;
            <Typography variant="inherit" color="secondary">
              {numeral(ordered).format("0,0a")}
            </Typography>
          </Typography>
          <Tooltip title={`${price} ${branch.currencyId}`} placement="top">
            <Typography variant="h6" color="primary" component="h2">
              {`${numeral(price).format("0.0a")} ${branch.currencyId}`}
            </Typography>
          </Tooltip>
        </Box>
      </CardContent>
      {/* <CardActions>
        <Button variant="outlined">
          <LocalizedMessage id={"item.viewItem"} />
        </Button>
      </CardActions> */}
    </Card>
  )
}

ItemCard.propTypes = {
  /**
   *
   */
}

export default ItemCard
