// React
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

// Material
import { Divider, useTheme } from "@material-ui/core";
import {
  Box,
  Paper,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  IconButton,
  TableRow,
  Tooltip,
  TextField,
  Button,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import useSkeleton from "Helpers/useSkeleton";
import useStateRef from "Helpers/useStateRef";
import {
  displaySnackbarErrors,
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
} from "Utils/displaySnackbarErrors";

// Components
import Title from "Components/Title";

// Factories

// Screens

// Assets

// Third Parties
import { useSnackbar } from "notistack";
import Joi from "@hapi/joi";
import { useIntl } from "react-intl";

// Services

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: 0,
    display: "flex",
    // margin: theme.spacing(0, 2),
    flexDirection: "column",
  },
  container: {
    maxHeight: 440,
  },
}));

// Ad-Hoc Components

/**
 * @name Translation
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Translation = ({ setHeading, permissions }) => {
  // Theme & Style Hooks
  const classes = useStyles();
  const Theme = useTheme();

  // Global State Hooks

  // State Hooks
  const [isFetchingRows, setIsFetchingRows] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [edit, setEdit] = useState(false);
  const [editId, setEditId] = useState(0);

  const [rows, setRows] = useStateRef([]);
  const [translations, setTranslations] = useState({
    english: "Hi",
    arabic: "أهلًا",
    french: "Ponsoir",

    german: "Hallo",

    russian: "Hall",

    espagnol: "HOla",

    japonais: "こんにちは",

    chinese: "你好",
  });

  // Effect Hooks
  useEffect(() => {
    setRows([createData(1), createData(2)]);
  }, [edit, translations]);

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect");

  // Event Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const onFieldChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setTranslations({ ...translations, [name]: value });
  };

  const handleSaveTranslation = () => {
    setEdit(false);
    setEditId(0);
  };

  const handleEdit = (id) => {
    setEdit(true);
    setEditId(id);
  };
  // Other

  const columns = [
    {
      id: "key",
      label: <LocalizedMessage id="translation.key" />,
      minWidth: 200,
    },
    {
      id: "english",
      label: <LocalizedMessage id="translation.english" />,
      minWidth: 200,
    },
    {
      id: "arabic",
      label: <LocalizedMessage id="translation.arabic" />,
      minWidth: 200,
    },
    {
      id: "french",
      label: <LocalizedMessage id="translation.french" />,
      minWidth: 200,
    },
    {
      id: "german",
      label: <LocalizedMessage id="translation.german" />,
      minWidth: 200,
    },
    {
      id: "russian",
      label: <LocalizedMessage id="translation.russian" />,
      minWidth: 200,
    },
    {
      id: "espagnol",
      label: <LocalizedMessage id="translation.espagnol" />,
      minWidth: 200,
    },
    {
      id: "japonais",
      label: <LocalizedMessage id="translation.japonais" />,
      minWidth: 200,
    },
    {
      id: "chinese",
      label: <LocalizedMessage id="translation.chinese" />,
      minWidth: 200,
    },
    {
      id: "action",
      minWidth: 200,
    },
  ];

  function createData(id) {
    return {
      id: id,
      key: "Hi",
      english:
        edit && editId === id ? (
          <TextField
            label="English Translation"
            name="english"
            value={translations.english}
            onChange={onFieldChange}
            variant="standard"
          />
        ) : (
          translations.english
        ),
      arabic:
        edit && editId === id ? (
          <TextField
            label="Arabic Translation"
            name="arabic"
            value={translations.arabic}
            onChange={onFieldChange}
            variant="standard"
          />
        ) : (
          translations.arabic
        ),
      french:
        edit && editId === id ? (
          <TextField
            label="French Translation"
            name="french"
            value={translations.french}
            onChange={onFieldChange}
            variant="standard"
          />
        ) : (
          translations.french
        ),

      german:
        edit && editId === id ? (
          <TextField
            label="German Translation"
            name="german"
            value={translations.german}
            onChange={onFieldChange}
            variant="standard"
          />
        ) : (
          translations.german
        ),

      russian:
        edit && editId === id ? (
          <TextField
            label="Russian Translation"
            name="russian"
            value={translations.russian}
            onChange={onFieldChange}
            variant="standard"
          />
        ) : (
          translations.russian
        ),

      espagnol:
        edit && editId === id ? (
          <TextField
            label="Espagnol Translation"
            name="espagnol"
            value={translations.espagnol}
            onChange={onFieldChange}
            variant="standard"
          />
        ) : (
          translations.espagnol
        ),

      japonais:
        edit && editId === id ? (
          <TextField
            label="Japonais Translation"
            name="japonais"
            value={translations.japonais}
            onChange={onFieldChange}
            variant="standard"
          />
        ) : (
          translations.japonais
        ),

      chinese:
        edit && editId === id ? (
          <TextField
            label="Chinese Translation"
            name="chinese"
            value={translations.chinese}
            onChange={onFieldChange}
            variant="standard"
          />
        ) : (
          translations.chinese
        ),

      action:
        !edit || (edit && editId !== id) ? (
          <Tooltip title={<LocalizedMessage id="general.edit" />}>
            <IconButton aria-label="edit" onClick={() => handleEdit(id)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        ) : (
          editId === id && (
            <Button onClick={handleSaveTranslation} color="primary">
              {<LocalizedMessage id="general.save" />}
            </Button>
          )
        ),
    };
  }

  // Component Render
  return (
    <Box className={classes.root}>
      <Title
        pageTitle={<LocalizedMessage id="Translation" key="Translation" />}
      />

      <Paper
        dir={Theme.direction}
        style={{
          padding: Theme.spacing(2),
        }}
        elevation={2}
        square
      >
        <Box
          style={{
            position: "relative",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between ",
            }}
          >
            <Typography variant="h6" component="h2">
              <LocalizedMessage id="translation.pageTitle" />
            </Typography>
          </Box>
          <Divider style={{ margin: "16px 0px 24px" }} />

          <Paper className={classes.root}>
            {/* Start translation Form */}
            {/* End translation Form */}
            {isFetchingRows && inputSkeleton ? (
              inputSkeleton
            ) : (
              <Box ref={inputRef}>
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{
                              minWidth: column.minWidth,
                            }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows
                        .getState()
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                            >
                              {columns.map((column) => {
                                const value = row[column.id];
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.format
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                );
                              })}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rows.getState().length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Box>
            )}
          </Paper>
        </Box>
      </Paper>
    </Box>
  );
};

Translation.propTypes = {
  /**
   *
   */
};

Translation.defaultProps = {
  /**
   *
   */
};

export default Translation;
