// React
import React, { useState } from "react";
import PropTypes from "prop-types";

// Material

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization"

// Components

// Factories

// Screens

// Assets

// Third Parties
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

// Services

// Styles
const useStyles = makeStyles(() => ({
  input: {
    outline: 'none',
    width: "100%",
    border: '1px solid #ccc',
    borderRadius: 4,
    padding: "18.5px 14px",
    font: "inherit",
    color: "currentColor",
    height: "1.1876em",
    margin: 0,
    display: "block",
    minWidth: 0,
    background: "none",
    animationName: "mui-auto-fill-cancel",
    letterSpacing: "inherit",
    animationDuration: "10ms",
  },
  label: {
    outline: 'none',
    width: "100%",
    padding: "18.5px 14px",
    font: "inherit",
    color: "currentColor",
    height: "1.1876em",
    margin: "auto",
    display: "flex",
    alignItems: "center",
    background: "none",
    animationName: "mui-auto-fill-cancel",
    letterSpacing: "inherit",
    animationDuration: "10ms",
    '&:hover': {
      background: "#f1f1f1",
    },
  }
}));

// Ad-Hoc Components

/**
 * @name AutoCompleteGoogleMaps
 * @summary 
 * @category 
 * @component
 * @description
 * > 
*/
const AutoCompleteGoogleMaps = ({ onGetLatLng }) => {
  // Theme & Style Hooks
  const classes = useStyles();

  // Global State Hooks

  // State Hooks
  const [address, setAddress] = useState('')

  // Effect Hooks

  // Other Hooks

  // Event Handlers
  const handleChange = address => {
    setAddress(address);
  };

  const handleSelect = address => {
    geocodeByAddress(address)
      .then(results => {
        setAddress(results[0].formatted_address)
        return getLatLng(results[0])
      })
      .then(latLng => {
        onGetLatLng(latLng)
      })
      .catch(error => console.error('Error', error));
  };

  // Other

  // Component Render
  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input
            {...getInputProps({
              placeholder: 'Search Places ...',
              className: classes.input
            })}
          />

          <div style={{ flexGrow: 0 }}>
            {loading && <div>Loading...</div>}
            {suggestions.map(suggestion => {
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className: classes.label,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              );
            })}
          </div>

        </div>
      )
      }
    </PlacesAutocomplete >
  );
}

AutoCompleteGoogleMaps.propTypes = {
  /**
   * 
   */

};

AutoCompleteGoogleMaps.defaultProps = {
  /**
   * 
   */

};

export default AutoCompleteGoogleMaps;
