// React
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

// Material
import {
  Fab,
  Paper,
  useTheme,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Tooltip,
  Typography,
  Divider,
} from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import AddIcon from "@material-ui/icons/Add"

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles"
import { LocalizedMessage } from "Helpers/Localization"
import useSkeleton from "Helpers/useSkeleton"
import useStateRef from "Helpers/useStateRef"
import {
  displaySnackbarErrors,
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
} from "Utils/displaySnackbarErrors"

// Components
import Title from "Components/Title"
import { TextField } from "Components/FormFields"
import AlertDialog from "Components/AlertDialog"

// Factories

// Screens
import AccountForm from "./AccountForm"

// Assets

// Third Parties
import { useSnackbar } from "notistack"

// Services
import {
  useGetAllAccounts,
  useDeleteAccount,
  useAddAccount,
  useEditAccount,
} from "Services/Accounts/"

// Styles
const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
}))

// Ad-Hoc Components
const columns = [
  {
    id: "name",
    label: <LocalizedMessage id="account.name" />,
    minWidth: 200
  },
  {
    id: "email",
    label: <LocalizedMessage id="account.email" />,
    minWidth: 100,
  },
  {
    id: "phoneNumber",
    label: <LocalizedMessage id="account.phoneNumber" />,
    minWidth: 100,
  },

  { id: "actions", label: "", minWidth: 150 },
]

/**
 * @name Accounts
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Accounts = ({
  branches,
  setHeading,
  refresh,
  permissions,
}) => {
  // Theme & Style Hooks
  const classes = useStyles()
  const Theme = useTheme()

  // Global State Hooks

  // State Hooks
  const [searchQuery, setSearchQuery] = React.useState("")
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(25)
  const [rows, setRows] = useStateRef([])
  const [isFetchingRows, setIsFetchingRows] = React.useState(true)
  const [editId, setEditId] = React.useState("")

  /* Account Form */
  const [AccountFormOpen, setAccountFormOpen] = useState(false)
  const [AccountFormMode, setAccountFormMode] = useState("")
  const [AccountFormData, setAccountFormData] = useState({})

  // Effect Hooks
  React.useEffect(
    () =>
      setHeading(
        <LocalizedMessage id="account.pageTitle" key="account.pageTitle" />
      ),
    []
  )

  useEffect(() => {
    getAllAccounts()
      .then((Accounts) => {
        const newAccounts = Accounts.map((Account) => createData(Account))
        setIsFetchingRows(false)
        setRows(newAccounts)
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar)
      })
  }, [])

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect")
  const getAllAccounts = useGetAllAccounts()
  const deleteAccount = useDeleteAccount()
  const addAccount = useAddAccount({ languageKey: "account" })
  const editAccount = useEditAccount({ languageKey: "account" })
  const { enqueueSnackbar } = useSnackbar()

  // Other Hooks

  // Event Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleSearchChange = (target, value) => {
    setSearchQuery(value)
    setPage(0)
  }

  const openAddAccountForm = () => {
    setAccountFormData({})
    setAccountFormMode("add")
    setAccountFormOpen(true)
  }

  const onEditClick = (account) => {
    setEditId(account.id)
    setAccountFormData(account)
    setAccountFormMode("edit")
    setAccountFormOpen(true)
  }

  const onDeleteClick = (id) => {
    deleteAccount(id)
      .then(() => {
        displayenqueueSnackbar(enqueueSnackbar, "deleted")
        setRows(rows.getState().filter((row) => row.id !== id))
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar)
      })
  }

  const onAddAccountHandler = (
    formData,
    validateForm,
    setFormErrors,
    setIsSubmiting,
    onCLoseAccountForm
  ) => {
    const validForm = validateForm()

    if (validForm) {
      setIsSubmiting(true)
      const {
        firstName,
        lastName,
        email,
        phoneNumber,
        password,
        roleId,
      } = formData
      addAccount({
        firstName,
        lastName,
        email,
        phoneNumber,
        password,
        roleId,
      })
        .then((response) => {
          displayenqueueSnackbar(enqueueSnackbar, "added")

          setRows([...rows.getState(), createData(response.data.data)])
          setIsSubmiting(false)
          onCLoseAccountForm()
        })
        .catch((errors) => {
          displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors)
          setIsSubmiting(false)
        })
    }
  }

  const onEditAccountHandler = (
    formData,
    validateForm,
    setFormErrors,
    setIsSubmiting,
    onCLoseAccountForm
  ) => {
    const validForm = validateForm()
    console.log(validForm)
    if (validForm) {
      setIsSubmiting(true)
      const {
        firstName,
        lastName,
        email,
        phoneNumber,
        password,
        roleId,
      } = formData
      editAccount({
        id: editId,
        firstName,
        lastName,
        email,
        phoneNumber,
        password,
        roleId
      })
        .then((response) => {
          displayenqueueSnackbar(enqueueSnackbar, "edited")

          const newRows = rows.getState()
          const index = newRows.findIndex((row) => row.id === editId)
          newRows.splice(index, 1, createData(response.data.data))
          setRows(newRows)
          setIsSubmiting(false)
          onCLoseAccountForm()
        })
        .catch((errors) => {
          displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors)
          setIsSubmiting(false)
        })
    }
  }

  // Other
  function createData(account) {
    return {
      id: account.id,
      name: account.firstName + " " + account.lastName,
      email: account.email,
      phoneNumber: account.phoneNumber,
      password: account.password,
      roldId: account.roldId,
      actions: (
        <Box>
          <Tooltip title={<LocalizedMessage id="general.edit" />}>
            <IconButton aria-label="edit" onClick={() => onEditClick(account)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
          <AlertDialog id={account.id} onAgree={onDeleteClick} />
        </Box>
      ),
    }
  }

  // Component Render
  return (
    <Box className={classes.root}>
      <Title
        pageTitle={
          <LocalizedMessage id="account.pageTitle" key="account.pageTitle" />
        }
      />
      <Paper
        dir={Theme.direction}
        style={{
          padding: Theme.spacing(0, 2, 2, 2),
        }}
        elevation={2}
        square
      >
        <Box
          style={{
            paddingBottom: Theme.spacing(9),
            position: "relative",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between ",
            }}
          >
            <Typography variant="h6" component="h2">
              <LocalizedMessage id="account.pageTitle" />
            </Typography>
            <TextField
              label={<LocalizedMessage id="general.search" />}
              size="small"
              value={searchQuery}
              onChange={handleSearchChange}
              variant="outlined"
            />
          </Box>
          <Divider style={{ margin: "0px 0px 16px" }} />

          <Tooltip title={<LocalizedMessage id="general.add" />}>
            <Fab
              disabled={isFetchingRows}
              color="primary"
              aria-label="add"
              style={{
                position: "absolute",
                bottom: Theme.spacing(.5),
                right: Theme.spacing(1),
              }}
              onClick={openAddAccountForm}
            >
              <AddIcon />
            </Fab>
          </Tooltip>

          <Paper className={classes.root}>
            {/* Start Account Form */}

            <AccountForm
              open={AccountFormOpen}
              setOpen={setAccountFormOpen}
              mode={AccountFormMode}
              editFormData={AccountFormData}
              onAdd={onAddAccountHandler}
              onSave={onEditAccountHandler}
              branches={branches}
              refresh={refresh}
              permissions={permissions}
            />
            {/* End Account Form */}
            {isFetchingRows && inputSkeleton ? (
              inputSkeleton
            ) : (
              <Box ref={inputRef}>
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(searchQuery
                        ? rows
                          .getState()
                          .filter(
                            (row) =>
                              row.name
                                .toLowerCase()
                                .indexOf(searchQuery.toLowerCase()) != -1 ||
                              searchQuery.toLowerCase() === ""
                          )
                        : rows.getState()
                      )
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                            >
                              {columns.map((column) => {
                                const value = row[column.id]
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.format
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                )
                              })}
                            </TableRow>
                          )
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rows.getState().length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Box>
            )}
          </Paper>
        </Box>
      </Paper>
    </Box>
  )
}

Accounts.propTypes = {
  /**
   *
   */
}

Accounts.defaultProps = {
  /**
   *
   */
}

export default Accounts
