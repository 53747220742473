// React
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

// Material
import {
  FormControl,
  Button,
  CircularProgress,
  Box,
  Paper,
  Typography,
  Divider,
  useTheme
} from "@material-ui/core"

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles"
import { LocalizedMessage } from "Helpers/Localization"
import { useForm } from "Helpers/Hooks"
import useSkeleton from "Helpers/useSkeleton"
import {
  displaySnackbarErrors,
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
} from "Utils/displaySnackbarErrors"

// Components
import { TextField } from "Components/FormFields"
import Title from "Components/Title"

// Factories

// Screens

// Assets

// Third Parties
import { useSnackbar } from "notistack"
import Joi from "@hapi/joi"
import { useHistory } from "react-router-dom"
import { useIntl } from "react-intl"

// Services
import {
  useGetGeneralSettings,
  useEditGeneralSettings,
} from "Services/GeneralSettings"

// Styles
const useStyles = makeStyles((theme) => {
  return {
    root: {
      width: "100%",
    },
    paper: {
      display: "flex",
      flexWrap: "wrap",
      margin: 8,
      "& > *": {
        width: theme.spacing(6),
        height: theme.spacing(12),
      },
    },
    formContainer: {
      flexGrow: 1,
      padding: theme.spacing(2),
      height: "100%",
      display: "flex",
      flex: 3,
      flexDirection: "column",
      justifyContent: "center",
    },
    form: {
      display: "flex",
    },
    formControl: {
      display: "flex",
      flex: 1,
    },
    radioBox: {
      width: '50%',
    },
  }
})

// Ad-Hoc Components
const validationSchema = Joi.object({
  branchSubscriptionGracePeriod: Joi.number().required().min(0).max(1000),
  branchSubscriptionCountingDownPeriod: Joi.number().required().min(0).max(1000),
  branchFirstSubscriptionPeriod: Joi.number().required().min(0).max(1000)
})
/**
 * @name EditGeneralSettings
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const EditGeneralSettings = ({ setHeading, permissions }) => {
  // Theme & Style Hooks
  const classes = useStyles()
  const theme = useTheme()

  // Global State Hooks

  // State Hooks
  const [isFetchingGeneralSettings, setIsFetchingGeneralSettings] = useState({})

  // Effect Hooks
  useEffect(() => {
    getGeneralSettings()
      .then(({ branchSubscriptionGracePeriod,
        branchSubscriptionCountingDownPeriod,
        branchFirstSubscriptionPeriod }) => {
        setFormData({
          branchSubscriptionGracePeriod,
          branchSubscriptionCountingDownPeriod,
          branchFirstSubscriptionPeriod
        })
        setIsFetchingGeneralSettings(false)
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar)
      })
  }, [])

  // Other Hooks
  const intl = useIntl()
  const [inputSkeleton, inputRef] = useSkeleton("rect")
  const getGeneralSettings = useGetGeneralSettings()
  const editEditGeneralSettings = useEditGeneralSettings({
    languageKey: "generalSettings",
  })
  const { enqueueSnackbar } = useSnackbar()
  let history = useHistory()

  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading,
    setIsLoading,
  } = useForm({ validationSchema, languageKey: "generalSettings" })

  // Event Handlers
  const onEditGeneralSettingsHandler = () => {
    const validForm = validateForm()
    console.log(validForm)
    if (validForm) {
      setIsSubmiting(true)
      const {
        branchSubscriptionGracePeriod,
        branchSubscriptionCountingDownPeriod,
        branchFirstSubscriptionPeriod
      } = formData
      editEditGeneralSettings({
        branchSubscriptionGracePeriod,
        branchSubscriptionCountingDownPeriod,
        branchFirstSubscriptionPeriod
      })
        .then((response) => {
          displayenqueueSnackbar(enqueueSnackbar, "edited")
          /** @TODO when eman responds */
          // setFormData({
          //   taxInclusive: response.data.tax === "inclusive" ? true : false,
          // })
          setIsSubmiting(false)
        })
        .catch((errors) => {
          displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors)
          setIsSubmiting(false)
        })
    }
  }

  // Other

  // Component Render
  return (
    <Box className={classes.root}>
      <Title
        pageTitle={
          <LocalizedMessage
            id="generalSettings.pageTitle"
            key="generalSettings.pageTitle"
          />
        }
      />
      {/* <Paper className={classes.paper} elevation={2}> */}
      <Paper
        dir={theme.direction}
        style={{
          padding: "4px 24px 8px 24px"
        }}
        elevation={2}
        square
      >
        <Typography
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            paddingTop: 16,
          }}
          variant="h6"
          component="h2"
        >
          <LocalizedMessage id="generalSettings.pageTitle" />
        </Typography>
        <Divider style={{ marginTop: 16 }} />
        <Box className={classes.root}>
          {isFetchingGeneralSettings && inputSkeleton ? (
            inputSkeleton
          ) : (
            <Box ref={inputRef} className={classes.formContainer}>
              <form className={classes.form} noValidate>
                <FormControl className={classes.formControl}>
                  {/* <CheckBox
                    fullWidth
                    name="taxInclusive"
                    tooltip={'Tax is inclusive?'}
                    helpKey="setup.taxSettings.taxInclusive"
                    label={<LocalizedMessage id="setup.taxSettings.taxInclusive" />}
                    onChange={onFormFieldChange}
                    value={formData.taxInclusive}
                    errors={formErrors.taxInclusive}
                    isSubmitting={isSubmitting}
                    isLoading={isLoading.taxInclusive}
                  /> */}
                  {(permissions.isAdmin ||
                    permissions.branchSubscriptionGracePeriod.includes("edit")) && (
                      <>
                        <TextField
                          required
                          label={<LocalizedMessage id="generalSettings.branchSubscriptionGracePeriod" />}
                          name="branchSubscriptionGracePeriod"
                          autoComplete="branchSubscriptionGracePeriod"
                          tooltip={"Branch Subscription Grace Period"}
                          helpKey="Menu.note.NameField"
                          autoFocus
                          fullWidth
                          // maxCharacters={50}
                          onChange={onFormFieldChange}
                          value={formData.branchSubscriptionGracePeriod}
                          errors={formErrors.branchSubscriptionGracePeriod}
                          isSubmitting={isSubmitting}
                          isLoading={isLoading.branchSubscriptionGracePeriod}
                          postHelperComponents={
                            <Typography
                              variant="caption"
                              color="textPrimary"
                              style={{ margin: theme.spacing(0, 1) }}
                            >
                              <LocalizedMessage id="generalSettings.graceInfo" />
                            </Typography>
                          }
                        />
                        <TextField
                          required
                          label={<LocalizedMessage id="generalSettings.branchSubscriptionCountingDownPeriod" />}
                          name="branchSubscriptionCountingDownPeriod"
                          autoComplete="branchSubscriptionCountingDownPeriod"
                          tooltip={"Branch Subscription Counting-Down Period"}
                          helpKey="Menu.note.NameField"
                          autoFocus
                          fullWidth
                          // maxCharacters={50}
                          onChange={onFormFieldChange}
                          value={formData.branchSubscriptionCountingDownPeriod}
                          errors={formErrors.branchSubscriptionCountingDownPeriod}
                          isSubmitting={isSubmitting}
                          isLoading={isLoading.branchSubscriptionCountingDownPeriod}
                          postHelperComponents={
                            <Typography
                              variant="caption"
                              color="textPrimary"
                              style={{ margin: theme.spacing(0, 1) }}
                            >
                              <LocalizedMessage id="generalSettings.countingdownInfo" />
                            </Typography>
                          }
                        />
                        <TextField
                          required
                          label={<LocalizedMessage id="generalSettings.branchFirstSubscriptionPeriod" />}
                          name="branchFirstSubscriptionPeriod"
                          autoComplete="branchFirstSubscriptionPeriod"
                          tooltip={"Branch First Subscription Period"}
                          helpKey="Menu.note.NameField"
                          autoFocus
                          fullWidth
                          // maxCharacters={50}
                          onChange={onFormFieldChange}
                          value={formData.branchFirstSubscriptionPeriod}
                          errors={formErrors.branchFirstSubscriptionPeriod}
                          isSubmitting={isSubmitting}
                          isLoading={isLoading.branchFirstSubscriptionPeriod}
                          postHelperComponents={
                            <Typography
                              variant="caption"
                              color="textPrimary"
                              style={{ margin: theme.spacing(0, 1) }}
                            >
                              <LocalizedMessage id="generalSettings.firstSubscriptionInfo" />
                            </Typography>
                          }
                        />

                        {/* <RadioBox
                          fullWidth
                          name="taxType"
                          tooltip={"Tax is inclusive?"}
                          helpKey="setup.taxSettings.taxesType"
                          onChange={onFormFieldChange}
                          value={formData.taxType}
                          errors={formErrors.taxType}
                          isSubmitting={isSubmitting}
                          isLoading={isLoading.taxType}
                          label={<LocalizedMessage id="setup.taxSettings.taxesType" />}
                          options={taxTypes}
                          defaultValue={formData.taxType || taxTypes[0].value}
                          className={classes.radioBox}
                        /> */}
                      </>
                    )}
                </FormControl>
              </form>
            </Box>
          )}
        </Box>

        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            type="button"
            color="primary"
            disabled={isSubmitting}
            onClick={onEditGeneralSettingsHandler}
          >
            {isSubmitting ? (
              <CircularProgress size={20} />
            ) : (
              <LocalizedMessage id="rolesNames.actions.save" />
            )}
          </Button>
        </Box>
      </Paper>
    </Box>
  )
}

EditGeneralSettings.propTypes = {
  /**
   *
   */
}

EditGeneralSettings.defaultProps = {
  /**
   *
   */
}

export default EditGeneralSettings
