// React
import React, { useEffect } from "react"
import PropTypes from "prop-types"

// Material
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
  useTheme,
  Grid,
} from "@material-ui/core"

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles"
import { useForm } from "Helpers/Hooks"
import { useGlobalState } from "Helpers/GlobalState"
import { LocalizedMessage } from "Helpers/Localization"
import { displaySnackbarErrors } from "Utils/displaySnackbarErrors"

// Components
import { TextField, CheckBox, RolesCheckBoxes } from "Components/FormFields"

// Factories

// Screens

// Assets

// Third Parties
import Joi from "@hapi/joi"
import _ from "lodash"
import { useSnackbar } from "notistack"

// Services
import {
  useGetPermissionsMatrix
} from "Services/Roles/"

// Styles
const useStyles = makeStyles(() => ({
  root: {
    height: "auto",
    flexGrow: 1,
  },
}))

// Ad-Hoc Components
const action = Joi.object().pattern(Joi.string(), Joi.boolean())

const permissionsMatrix = Joi.object().pattern(Joi.string(), action)

const validationSchema = Joi.object({
  name: Joi.string().required().min(3).max(50),
  isAdmin: Joi.boolean(),
  permissionsMatrix: Joi.when("isAdmin", {
    is: false,
    then: permissionsMatrix.required().min(1),
  }),
})
// Ad-Hoc Components
/**
 * @name RoleForm
 * @summary
 * @Role
 * @component
 * @description
 * >
 */
const RoleForm = ({
  editFormData,
  onAdd,
  onSave,
  onCancel,
  mode,
  open,
  setOpen,
  full,
  keyy,
}) => {
  // Theme & Style Hooks
  const classes = useStyles()
  const theme = useTheme()

  // Global State Hooks
  const [permissionsMatrix, setPermissionsMatrix] = useGlobalState(
    "sharedData.permissionsMatrix"
  )

  // State Hooks

  // Effect Hooks
  useEffect(() => {
    getPermissionsMatrix()
      .then((res) => {
        setPermissionsMatrix({ data: res, isFetchingRows: false })
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar)
      })
  }, [])

  useEffect(() => {
    console.log("editFormData", editFormData)
    if (mode === "edit") {
      setFormData({
        name: editFormData.name,
        isAdmin: editFormData.isAdmin,
        permissionsMatrix: editFormData.isAdmin ? permissionsMatrix.data : editFormData.permissionsMatrix
      })
    } else {
      setFormData({ permissionsMatrix: permissionsMatrix.data })
    }
  }, [open, permissionsMatrix.data])

  // Other Hooks
  const getPermissionsMatrix = useGetPermissionsMatrix()
  const { enqueueSnackbar } = useSnackbar()

  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading,
  } = useForm({ validationSchema, languageKey: "role" })

  // Event Handlers
  const openAddRoleForm = (parentRole) => {
    setOpen(true)
  }

  const onCLoseRoleForm = () => {
    setOpen(false)
    setFormData({})
    setFormErrors({})
    onCancel()
  }

  const onAddRole = () => {
    onAdd(
      keyy,
      formData,
      validateForm,
      setFormErrors,
      setIsSubmiting,
      onCLoseRoleForm
    )
  }

  const onSaveRole = () => {
    onSave(
      formData,
      validateForm,
      setFormErrors,
      setIsSubmiting,
      onCLoseRoleForm
    )
  }

  // Other
  console.log(
    `%cRoleForm %cRerender`,
    "font-weight: bold; color: #000; background-color: #FFE110;",
    ""
  )

  // Component Render
  return (
    <Dialog
      open={open}
      onClose={onCLoseRoleForm}
      scroll={"paper"}
      fullScreen={full}
      style={{ direction: theme.direction }}
    >
      <DialogTitle>
        <LocalizedMessage id="role.formTitle" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {mode === "add" ? (
            <Typography component="span">
              <LocalizedMessage id="role.addNew" />
            </Typography>
          ) : (
              <Typography component="span">
                <LocalizedMessage id="role.edit" />
                <b>{formData.name}</b>
              </Typography>
            )}
        </DialogContentText>
        <Grid container justify="center" xs={12} spacing={2}>
          <Grid xs={12}>
            <form className={classes.form} noValidate>
              <TextField
                required
                label={<LocalizedMessage id="role.name" />}
                name="name"
                autoComplete="name"
                tooltip={"Role name"}
                helpKey="Role.NameField"
                autoFocus
                fullWidth
                // maxCharacters={50}
                onChange={onFormFieldChange}
                value={formData.name}
                errors={formErrors.name}
                isSubmitting={isSubmitting}
                isLoading={isLoading.name}
              />

              <CheckBox
                fullWidth
                name="isAdmin"
                tooltip={"Admin access?"}
                helpKey="role.isAdmin"
                label={<LocalizedMessage id="role.isAdmin" />}
                onChange={onFormFieldChange}
                value={formData.isAdmin}
                errors={formErrors.isAdmin}
                isSubmitting={isSubmitting}
                isLoading={isLoading.isAdmin}
              />

              {!formData.isAdmin && (
                <RolesCheckBoxes
                  required
                  label={<LocalizedMessage id="role.permissionsMatrix" />}
                  name="permissionsMatrix"
                  autoComplete="permissionsMatrix"
                  tooltip={"Role permissionsMatrix"}
                  helpKey="Role.CheckboxField"
                  fullWidth
                  // maxCharacters={50}
                  onChange={onFormFieldChange}
                  value={formData.permissionsMatrix}
                  errors={formErrors.permissionsMatrix}
                  isSubmitting={isSubmitting}
                  isLoading={isLoading.permissionsMatrix}
                  permissionsMatrix={permissionsMatrix}
                />
              )}
            </form>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCLoseRoleForm} color="secondary">
          <LocalizedMessage id="general.cancel" />
        </Button>
        {mode === "add" ? (
          <Button
            type="button"
            color="primary"
            disabled={isSubmitting}
            onClick={onAddRole}
          >
            {isSubmitting ? (
              <CircularProgress size={20} />
            ) : (
                <LocalizedMessage id="role.add.submit" />
              )}
          </Button>
        ) : (
            <Button
              type="button"
              color="primary"
              disabled={isSubmitting}
              onClick={onSaveRole}
            >
              {isSubmitting ? (
                <CircularProgress size={20} />
              ) : (
                  <LocalizedMessage id="role.edit.submit" />
                )}
            </Button>
          )}
      </DialogActions>
    </Dialog>
  )
}

RoleForm.propTypes = {
  /**
   *
   */
}

RoleForm.defaultProps = {
  onAdd: () => { },
  onSave: () => { },
  onCancel: () => { },
  mode: "add",
  formData: {},
  open: false,
  setOpen: () => { },
}

export default RoleForm
