// React
import React from "react";
import PropTypes from "prop-types";

// Material
import { Grid, Link } from "@material-ui/core";

// Globals

// Helpers
import { LocalizedMessage } from "Helpers/Localization";

// Components

// Factories

// Screens

// Assets

// Third Parties

// Styles

// Ad-Hoc Components

/**
 * @name SignInNav
 * @summary Sign In Nav Component
 * @category Screens / Auth
 * @component
 * @description
 * > Navigation links to be rendered under SignInForm
 */
const SignInNav = ({ goToForgetPasswordHandler }) => {
  // Theme & Style Hooks

  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers
  const onClickForgetPassword = (e) => {
    e.preventDefault();
    goToForgetPasswordHandler();
  };

  // Other

  // Component Render
  return (
    <Grid container>
      <Grid item xs>
        <Link href="#" variant="body2" onClick={onClickForgetPassword}>
          <LocalizedMessage id="auth.signIn.forgetPassword" />
        </Link>
      </Grid>
    </Grid>
  );
};

SignInNav.propTypes = {
  /**
   * Function that hides the SignInForm and shows ForgetPasswordForm
   */
  goToForgetPasswordHandler: PropTypes.func.isRequired,
};

export default SignInNav;
