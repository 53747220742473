// React
import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { unstable_batchedUpdates } from "react-dom";

// Material
import {
  Toolbar,
  List,
  ListItem,
  Button,
  Grow,
  Paper,
  Popper,
  ListItemIcon,
  ListItemText,
  Menu as MuiMenu,
  MenuItem,
  Box,
  Typography,
  Divider,
  useTheme,
  useMediaQuery,
  ClickAwayListener,
  MenuList,
  Tooltip,
} from "@material-ui/core";
import { withStyles } from "@material-ui/styles";
import DashboardIcon from "@material-ui/icons/Dashboard";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import CssBaseline from "@material-ui/core/CssBaseline";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import TranslateIcon from "@material-ui/icons/Translate";
import AssessmentIcon from "@material-ui/icons/Assessment";
import SettingsIcon from "@material-ui/icons/Settings";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import { useGlobalState } from "Helpers/GlobalState";

// Components
import { EditGeneralSettings } from "Screens/Portal/GeneralSettings"

// Factories

// Screens
import AppFooter from "./Footer";
import { Dashboard } from "Screens/Portal/Dashboard";
import { Accounts } from "Screens/Portal/Accounts";
import { Roles } from "Screens/Portal/Roles";
import { Customers } from "Screens/Portal/Customers";
import { EditProfile } from "Screens/Portal/EditProfile";
import { Translation } from "Screens/Portal/Translation";

// Assets
import { SahaabColoredLogo } from "Assets/Images";

// Services
import { useGetAllRoles } from "Services/Roles/";
import useGetAllCountries from "Services/Countries/useGetAllCountries";
import useGetAllCurrencies from "Services/Currencies/useGetAllCurrencies";

// Third Parties
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import { useSnackbar } from "notistack";

import Layout, {
  Root,
  getHeader,
  getDrawerSidebar,
  getSidebarTrigger,
  getSidebarContent,
  getCollapseBtn,
  getContent,
  getFooter,
} from "@mui-treasury/layout";
import styled from "styled-components";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useRouteMatch,
  useLocation,
  useHistory,
} from "react-router-dom";
import _ from "lodash";

const drawerWidth = 240;

const Header = getHeader(styled);
const DrawerSidebar = getDrawerSidebar(styled);
const SidebarTrigger = getSidebarTrigger(styled);
const SidebarContent = getSidebarContent(styled);
const CollapseBtn = getCollapseBtn(styled);
const Content = getContent(styled);
const Footer = getFooter(styled);

// Styles
const useStyles = makeStyles((theme) => ({
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(2, 0.7),
    "& img": {
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      maxWidth: "100%",
      maxHeight: "150px",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    minHeight: "85vh",
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  footer: {
    position: "sticky",
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

// Ad-Hoc Components

/**
 * @name Portal
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Portal = () => {
  // Theme & Style Hooks
  const classes = useStyles();
  const Theme = useTheme();

  // Global State Hooks
  /** Shared Data States */
  const [roles, setRoles] = useGlobalState("sharedData.roles");
  const [countries, setCountries] = useGlobalState("sharedData.countries");
  const [currencies, setCurrencies] = useGlobalState("sharedData.currencies");
  /** */

  const [accountState, setAccountState] = useGlobalState("userData.account");
  const [userAuth, setUserAuth] = useGlobalState("userData.auth");
  const [permissions] = useGlobalState("userData.permissions");
  const [dataToShowInPortal] = useGlobalState("userData.dataToShowInPortal");

  // State Hooks
  const [heading, setHeading] = useState("");
  const [keyy, setkeyy] = useState(1);

  // Effect Hooks
  useEffect(() => {
    const d = {
      role: [getAllRoles, setRoles],
    };
    const isFetchingRows = false;
    unstable_batchedUpdates(() => {
      Object.entries(d).forEach(async ([key, value]) => {
        if (permissions.isOwner || permissions.isAdmin || permissions[key]) {
          value[1]({
            data: await value[0](),
            isFetchingRows,
          });
        }
      });
    });

    // const fetchCommonData = async () => {
    //   const countries = await getAllCountries();
    //   const currencies = await getAllCurrencies();

    //   unstable_batchedUpdates(() => {
    //     setCountries({ data: countries, isFetchingRows });
    //     setCurrencies({ data: currencies, isFetchingRows });
    //   });
    // };
    // fetchCommonData();
  }, [permissions]);

  // Other Hooks
  /** Gets */
  const getAllRoles = useGetAllRoles();
  // const getAllCountries = useGetAllCountries();
  // const getAllCurrencies = useGetAllCurrencies();
  /** */

  let history = useHistory();
  const routeMatch = useRouteMatch();
  const routerLocation = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const showSidebarTrigger = !useMediaQuery(Theme.breakpoints.up("md"));
  const showSidebarCollapseBtn = useMediaQuery(Theme.breakpoints.up("md"));

  // Event Handlers
  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
    }
  }

  const handleLogout = () => {
    setUserAuth({
      loaded: false,
      token: null,
    });
    setAccountState({});
    localStorage.clear();
    history.go(0);
  };

  const refreshKey = () => {
    setkeyy(keyy + 1);
  };

  // Other
  const scheme = Layout();

  scheme.configureHeader((builder) => {
    builder.create("appHeader").registerConfig("xs", {
      position: "sticky",
      clipped: false,
    });
  });

  scheme.configureEdgeSidebar((builder) => {
    builder
      .create("appSidebar", {
        anchor: "left",
      })
      .registerTemporaryConfig("xs", {
        //   anchor: "left",
        width: "auto", // 'auto' is only valid for temporary variant
      })
      .registerPermanentConfig("md", {
        width: drawerWidth, // px, (%, rem, em is compatible)
        collapsible: true,
        collapsedWidth: 64,
        headerMagnetEnabled: true,
      });
  });

  // Component Render
  return (
    <Root theme={Theme} scheme={scheme}>
      {({ state }) => {
        const HeaderStyled = withStyles((theme) => ({
          root: {
            width: "100%",
            [theme.breakpoints.up("xs")]: {
              position: "sticky",
            },
            [theme.breakpoints.up("md")]: {
              width: state.sidebar.appSidebar.collapsed
                ? `calc(100% - 64px)`
                : `calc(100% - ${drawerWidth}px)`,
            },
          },
        }))(Header);
        return (
          <>
            <CssBaseline />
            <HeaderStyled>
              <Toolbar>
                {showSidebarTrigger && (
                  <SidebarTrigger sidebarId="appSidebar" />
                )}
                <Typography variant="h6" noWrap>
                  {heading}
                </Typography>
                <Box
                  display="flex"
                  flex="2"
                  flexDirection="row"
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <PopupState variant="popper" popupId="demo-popup-popper">
                    {(popupState) => (
                      <div>
                        <ListItem
                          {...bindToggle(popupState)}
                          aria-controls={
                            popupState.isOpen ? "menu-list-grow" : undefined
                          }
                          aria-haspopup="true"
                        >
                          <ListItemIcon style={{ minWidth: 40 }}>
                            <AccountCircleIcon fontSize="large" />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <LocalizedMessage id={accountState.firstName} />
                            }
                            style={{ margin: "0 8px" }}
                          />

                          {popupState.isOpen ? <ExpandLess /> : <ExpandMore />}
                        </ListItem>
                        <Popper
                          style={{ zIndex: 105 }}
                          {...bindPopper(popupState)}
                          transition
                        >
                          {({ TransitionProps }) => (
                            <Grow {...TransitionProps}>
                              <Paper>
                                <ClickAwayListener
                                  onClickAway={popupState.close}
                                >
                                  <MenuList
                                    autoFocusItem={popupState.isOpen}
                                    id="menu-list-grow"
                                    onKeyDown={handleListKeyDown}
                                  >
                                    <MenuItem
                                      style={{
                                        direction: Theme.direction,
                                        display: "flex",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Box
                                        style={{
                                          display: "flex",
                                          flexDirection: "row",
                                          alignContent: "center",
                                          alignItems: "center",
                                        }}
                                      >
                                        {/* <ListItemAvatar
                                          style={{
                                            margin: Theme.spacing(2, 2, 2, 0.3),
                                          }}
                                        >
                                          <Avatar>
                                            <Image
                                              src={AccountCircleVector}
                                              height="40"
                                              width="40"
                                            />
                                          </Avatar>
                                        </ListItemAvatar> */}
                                        <ListItemText
                                          primary={
                                            <Typography
                                              variant="h5"
                                              component="h2"
                                            >
                                              {accountState.firstName}
                                            </Typography>
                                          }
                                        />
                                      </Box>
                                    </MenuItem>
                                    <Divider variant="middle" />

                                    <MenuItem
                                      style={{
                                        direction: Theme.direction,
                                        paddingTop: 10,
                                      }}
                                      onClick={() => {
                                        history.replace("/portal/editProfile");
                                      }}
                                    >
                                      <ListItemIcon>
                                        <AccountCircleIcon
                                          style={{
                                            margin: Theme.spacing(0, 1.5, 0, 0),
                                          }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={
                                          <LocalizedMessage id="general.myAccount" />
                                        }
                                      />
                                    </MenuItem>

                                    <MenuItem
                                      style={{ direction: Theme.direction }}
                                      onClick={handleLogout}
                                    >
                                      <ListItemIcon>
                                        <ExitToAppIcon
                                          style={{
                                            margin: Theme.spacing(0, 1.5, 0, 0),
                                          }}
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        primary={
                                          <LocalizedMessage id="general.signOut" />
                                        }
                                      />
                                    </MenuItem>
                                  </MenuList>
                                </ClickAwayListener>
                              </Paper>
                            </Grow>
                          )}
                        </Popper>
                      </div>
                    )}
                  </PopupState>
                </Box>
              </Toolbar>
            </HeaderStyled>

            <DrawerSidebar sidebarId={"appSidebar"}>
              <SidebarContent
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Box className={classes.logoContainer}>
                  <img src={SahaabColoredLogo} alt="Sahaab Colored Logo" />
                </Box>
                <Divider />

                <List>
                  <Link
                    to={`${routeMatch.path}/dashboard`}
                    component={React.forwardRef((props, ref) => {
                      return (
                        <ListItem
                          ref={ref}
                          button
                          key="Dashboard"
                          onClick={(evt) => {
                            evt.preventDefault();
                            props.navigate();
                          }}
                          href={props.to}
                          selected={
                            routerLocation.pathname ===
                            `${routeMatch.path}/dashboard`
                          }
                        >
                          {props.children}
                        </ListItem>
                      );
                    })}
                  >
                    <ListItemIcon>
                      <DashboardIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={<LocalizedMessage id="dashboard.pageTitle" />}
                    />
                  </Link>
                  {/* {[
                    {
                      text: <LocalizedMessage id="setup.pageTitle" />,
                      permissionName: "setup",
                      icon: <SettingsIcon />,
                      link: `${routeMatch.path}/setup`,
                    },
                    {
                      text: <LocalizedMessage id="menu.pageTitle" />,
                      permissionName: "menu",
                      icon: <RestaurantMenuIcon />,
                      link: `${routeMatch.path}/menu`,
                    },
                    {
                      text: <LocalizedMessage id="stockCapacity.pageTitle" />,
                      permissionName: "menuItemStock",
                      icon: <ListAltIcon />,
                      link: `${routeMatch.path}/editStock`,
                    },
                    {
                      text: <LocalizedMessage id="POS.pageTitle" />,
                      permissionName: "pos",
                      icon: <TabletIcon />,
                      link: `${routeMatch.path}/POS`,
                    },
                  ].map((item, index) => {
                    if (dataToShowInPortal.find((el) => el === item.permissionName))
                      return (
                        <Link
                          to={item.link}
                          component={React.forwardRef((props, ref) => {
                            return (
                              // <a ref={ref}>💅 </a>
                              <ListItem
                                ref={ref}
                                button
                                key={item.text}
                                onClick={(evt) => {
                                  evt.preventDefault();
                                  props.navigate();
                                }}
                                href={props.to}
                                selected={routerLocation.pathname === item.link}
                              >
                                {props.children}
                              </ListItem>
                            );
                          })}
                        >
                          <ListItemIcon>{item.icon}</ListItemIcon>
                          <ListItemText primary={item.text} />
                        </Link>
                      );
                  })}*/}
                </List>
                <Divider />
                <List>
                  {[
                    // {
                    //   text: <LocalizedMessage id="branch.pageTitle" />,
                    //   permissionName: "branch",
                    //   icon: <LocationCityIcon />,
                    //   link: `${routeMatch.path}/branches`,
                    // },
                    {
                      text: <LocalizedMessage id="account.pageTitle" />,
                      permissionName: "account",
                      icon: <AccountBoxIcon />,
                      link: `${routeMatch.path}/accounts`,
                    },
                    {
                      text: <LocalizedMessage id="role.pageTitle" />,
                      permissionName: "role",
                      icon: <AccountTreeIcon />,
                      link: `${routeMatch.path}/roles`,
                    },
                    {
                      text: <LocalizedMessage id="customer.pageTitle" />,
                      permissionName: "customer",
                      icon: <AssessmentIcon />,
                      link: `${routeMatch.path}/Customers`,
                    },
                    // {
                    //   text: <LocalizedMessage id="export.pannelName" />,
                    //   permissionName: "export",
                    //   icon: <ImportExportIcon />,
                    //   link: `${routeMatch.path}/export`,
                    // },
                    {
                      text: <LocalizedMessage id="translation.pageTitle" />,
                      permissionName: "translation",
                      icon: <TranslateIcon />,
                      link: `${routeMatch.path}/translation`,
                    },
                    {
                      text: <LocalizedMessage id="generalSettings.pageTitle" />,
                      permissionName: "generalSettings",
                      icon: <SettingsIcon />,
                      link: `${routeMatch.path}/generalSettings`,
                    },
                  ].map((item, index) => {
                    if (
                      dataToShowInPortal.find(
                        (el) => el === item.permissionName
                      )
                    )
                      return (
                        <Link
                          to={item.link}
                          component={React.forwardRef((props, ref) => {
                            return (
                              <ListItem
                                ref={ref}
                                button
                                key={item.text}
                                onClick={(evt) => {
                                  evt.preventDefault();
                                  props.navigate();
                                }}
                                href={props.to}
                                selected={routerLocation.pathname === item.link}
                              >
                                {props.children}
                              </ListItem>
                            );
                          })}
                        >
                          <ListItemIcon>{item.icon}</ListItemIcon>
                          <ListItemText primary={item.text} />
                        </Link>
                      );
                  })}
                </List>
                <Divider />
              </SidebarContent>

              {showSidebarCollapseBtn && <CollapseBtn />}
            </DrawerSidebar>
            <Content className={classes.content}>
              <Switch>
                <Route path={`${routeMatch.path}/dashboard`}>
                  {
                    <Dashboard
                      setHeading={setHeading}
                      permissions={permissions}
                      dataToShow={dataToShowInPortal}
                    />
                  }
                </Route>
                {/* {dataToShowInPortal.includes("menu") &&
                  <Route path={`${routeMatch.path}/menu`}>
                    <Menu setHeading={setHeading} permissions={permissions} />
                  </Route>
                }
                {dataToShowInPortal.includes("menuItemStock") &&
                  <Route path={`${routeMatch.path}/editStock`}>
                    <StockCapacity
                      setHeading={setHeading}
                      permissions={permissions}
                    />
                  </Route>
                }
                {dataToShowInPortal.includes("setup") &&
                  <Route path={`${routeMatch.path}/Setup`}>
                    <Setup
                      setHeading={setHeading}
                      permissions={permissions}
                    />
                  </Route>
                }
                {dataToShowInPortal.includes("pos") &&
                  <Route path={`${routeMatch.path}/POS`}>
                    <POS setHeading={setHeading} permissions={permissions} />
                  </Route>
                }
               

                {dataToShowInPortal.includes("branch") &&
                  <Route path={`${routeMatch.path}/Branches`}>
                    <Branches
                      setHeading={setHeading}
                      branches={branches}
                      setBranches={updateBranches}
                    />
                  </Route>
                } */}

                <Route path={`${routeMatch.path}/EditProfile`}>
                  <EditProfile setHeading={setHeading} />
                </Route>
                {dataToShowInPortal.includes("account") && (
                  <Route path={`${routeMatch.path}/Accounts`}>
                    <Accounts
                      setHeading={setHeading}
                      refresh={refreshKey}
                      permissions={permissions}
                    />
                  </Route>
                )}
                {dataToShowInPortal.includes("role") && (
                  <Route path={`${routeMatch.path}/Roles`}>
                    <Roles setHeading={setHeading} />
                  </Route>
                )}
                {dataToShowInPortal.includes("translation") && (
                  <Route path={`${routeMatch.path}/translation`}>
                    <Translation setHeading={setHeading} />
                  </Route>
                )}

                {dataToShowInPortal.includes("customer") && (
                  <Route path={`${routeMatch.path}/Customers`}>
                    <Customers
                      setHeading={setHeading}
                      permissions={permissions}
                    />
                  </Route>
                )}

                {dataToShowInPortal.includes("generalSettings") && (
                  <Route path={`${routeMatch.path}/generalSettings`}>
                    <EditGeneralSettings
                      setHeading={setHeading}
                      permissions={permissions}
                    />
                  </Route>
                )}

                {/* {permissions.isOwner &&
                  <Route path={`${routeMatch.path}/EditBrand`}>
                    <EditBrand setHeading={setHeading} />
                  </Route>
                }
                {dataToShowInPortal.includes("export") &&
                  <Route path={`${routeMatch.path}/export`}>
                    <Export setHeading={setHeading} />
                  </Route>
                } */}

                <Route path={routeMatch.path}>
                  <Redirect to={`${routeMatch.path}/Dashboard`} />
                </Route>
              </Switch>
            </Content>
            <Footer className={classes.footer}>
              <AppFooter />
            </Footer>
          </>
        );
      }}
    </Root>
  );
};

Portal.propTypes = {
  /**
   *
   */
};

export default Portal;
