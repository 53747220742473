/**
 * @module State
 * @description
 * > Provides the global state initial values
 * @category Globals
 */

/**
 * @name InitialGlobalState
 * @summary Global State Initial Values
 * @constant
 * @type {object}
 * @property {object} global Global State Object for all components
 * @property {string} global.locale App current locale
 */
const InitialGlobalState = {
  global: {
    locale: "en",
    app: {
      loaded: false,
    },
  },
  userData: {
    auth: {
      loaded: false,
      token: null,
    },
    account: {
      firstName: null,
      lastName: null,
      email: null,
      isOwner: null,
      isAdmin: null,
      adminPanelRole: {},
      permissions: { isOwner: false, isAdmin: false },
      dataToShowInPortal: []
    },
    serverStorage: {},
  },
  sharedData: {
    roles: {
      isFetchingRows: true,
      data: [],
    },
    countries: {
      isFetchingRows: true,
      data: [],
    },
    currencies: {
      isFetchingRows: true,
      data: [],
    },
    permissionsGroups: {
      isFetchingRows: true,
      data: [],
    },
    permissionsMatrix: {
      isFetchingRows: true,
      data: [],
    },
  },
}

export default InitialGlobalState
