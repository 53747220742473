// React
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

// Material
import {
  Box,
  Grid,
  Radio as MUIRadioBox,
  FormControl,
  FormControlLabel,
  FormLabel,
  useMediaQuery,
  FormHelperText,
  useTheme,
} from "@material-ui/core"
import { withStyles } from "@material-ui/styles"

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles"
import useSkeleton from "Helpers/useSkeleton"

// Components
import HelpIcon from "Components/HelpIcon"

// Factories

// Screens

// Assets

// Help

// Third Parties
import uuid from "uuid"

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    minWidth: 0,
    flexShrink: 1,
  },
  fieldWrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    minWidth: 0,
    flexShrink: 1,
    flexWrap: "wrap",
  },
  field: {
    flex: 1,
    minWidth: 0,
    flexShrink: 1,
  },
  formControl: {
    flex: 1,
    minWidth: 0,
    flexShrink: 1,
  },
}))

const MUIRadioBoxExtended = withStyles((theme) => ({
  root: {
    minWidth: 0,
    flexShrink: 1,
  },
  select: {
    minWidth: 0,
    flexShrink: 1,
  },
  filled: {
    minWidth: 0,
    flexShrink: 1,
  },
  outlined: {
    minWidth: 0,
    flexShrink: 1,
  },
}))(MUIRadioBox)

// Ad-Hoc Components

/**
 * @name RadioBox
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const RadioBox = ({
  className,
  style,
  tooltip,
  helpKey,
  helpIconProps,
  options,
  getOptionLabel,
  getOptionSelected,
  defaultValue,
  onChange,
  margin,
  variant,
  autoComplete,
  helperText,
  size,
  inputProps,
  isLoading,
  isSubmitting,
  disabled,
  setDefaultValue,
  label,
  id,
  required,
  name,
  value,
  errors,
  preHelperComponents,
  postHelperComponents,
  ...otherProps
}) => {
  // Theme & Style Hooks
  const theme = useTheme()
  const classes = useStyles()
  const [selectedValue, setSelectedValue] = React.useState(defaultValue)

  // Global State Hooks

  // State Hooks
  const [inputFieldID] = useState(id || uuid.v4())

  // Effect Hooks
  useEffect(() => {
    onChangeHandler({
      persist: () => { },
      target: {
        name,
        value: defaultValue,
      },
    })
    console.log(
      `%cSelect %cField Mount -> %cName: %c${name}`,
      "font-weight: bold; color: burlywood",
      "",
      "font-weight: bold;",
      ""
    )
  }, [])

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect")
  const [helperTextSkeleton, helperTextRef] = useSkeleton("rect")

  // Event Handlers
  const onChangeHandler = (e) => {
    if (typeof onChange == "function") {
      onChange(name, e.target.value)
      setSelectedValue(e.target.value)
    }
  }

  // Other
  console.log(
    `%RadioBox %cRerender -> %cName: %c${name}`,
    "font-weight: bold; color: burlywood",
    "",
    "font-weight: bold;",
    ""
  )

  console.log(selectedValue)

  // Component Render
  return (
    <Box className={className}>
      <Box className={classes.root} style={style}>
        <FormControl
          variant={variant}
          className={classes.formControl}
          margin={margin}
          size={size}
          component="fieldset"
        >
          <FormLabel component="legend">{label}</FormLabel>
          <Box className={classes.fieldWrapper}>
            {isLoading && inputSkeleton
              ? inputSkeleton
              : options.map((option) => {
                return (
                  <FormControlLabel
                    style={{ display: "block" }}
                    value="bottom"
                    control={
                      <MUIRadioBoxExtended
                        ref={inputRef}
                        key={option}
                        checked={selectedValue === option.value}
                        onChange={onChangeHandler}
                        value={option.value}
                        labelId={`${inputFieldID}-label`}
                        // defaultValue={defaultValue && getOptionLabel(defaultValue)}
                        inputProps={{
                          autoComplete,
                          disabled: isSubmitting || disabled,
                          id: inputFieldID,
                          ...inputProps,
                        }}
                        disabled={isSubmitting || disabled}
                        variant={variant}
                        className={classes.field}
                        id={inputFieldID}
                        required={required}
                        label={label}
                        name={name}
                        {...otherProps}
                      ></MUIRadioBoxExtended>
                    }
                    label={option.text}
                    labelPlacement="end"
                  />
                )
              })}
            {/* <HelpIcon
              {...{
                tooltip,
                helpKey,
                size,
                isLoading,
                modalTitle: label,
                ...helpIconProps,
              }}
            /> */}
          </Box>
          <Box className={classes.root}>
            {(helperText || errors || preHelperComponents) && (
              <Box
                style={{ display: "flex", flexDirection: "column", flex: 1 }}
              >
                {preHelperComponents && <Box>{preHelperComponents}</Box>}
                <FormHelperText
                  error={Boolean(errors && errors.length > 0)}
                  className={classes.helperTextWrapper}
                  component="div"
                  style={{
                    display: "flex",
                    flex: 1,
                  }}
                >
                  <span className={classes.helperTextWrapper}>
                    {helperText &&
                      !errors &&
                      (isLoading && helperTextSkeleton !== undefined ? (
                        helperTextSkeleton
                      ) : (
                          <span ref={helperTextRef}>{helperText}</span>
                        ))}
                    {errors && (
                      <span
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        {errors.map((errorMessage, i) => (
                          <span key={i}>{errorMessage}</span>
                        ))}
                      </span>
                    )}
                  </span>
                </FormHelperText>
                {postHelperComponents && <Box>{postHelperComponents}</Box>}
              </Box>
            )}
            <HelpIcon
              style={{ visibility: "hidden", height: 0 }}
              tooltip={tooltip}
              helpKey={helpKey}
              modalTitle={label}
              size={size}
              {...helpIconProps}
            />
          </Box>
        </FormControl>
      </Box>
    </Box>
  )
}

RadioBox.propTypes = {
  /**
   *
   */
}

RadioBox.defaultProps = {
  options: Array(),
  getOptionLabel: (x) => x,
  getOptionSelected: (x) => x,
  variant: "outlined",
  margin: "normal",
  autoComplete: "off",
  multiple: false,
  getOptionLabel: (option) => option.text,
  getOptionSelected: (option) => option.value,
}

export default RadioBox
