/**
 * @module Theme
 * @description
 * > Exports a function that returns the Material UI Theme Customizations
 * @category Globals
 * @see {@link https://material-ui.com/customization/theming/|Material UI Theming}
 */

// Material UI Colors
import { green as GreenHue, grey as GreyHue } from "@material-ui/core/colors";

/**
 * @description Material UI Theme Factory Function
 * @type {function(object):object}
 * @param {object} dependencies Theme Dependencies
 * @param {string} dependencies.locale App current locale
 * @return {ThemeObject}
 */
const ThemeFactory = ({ locale }) =>
  /**
   * Theme Object
   * @typedef {Object} ThemeObject
   * @property {string} direction Theme DOM Direction (Changeable according language)
   * @property {object} typography Typography Theme
   * @property {string} typography.fontFamily Typography Font Families
   * @property {object} palette Theme Palette
   * @property {object} palette.primary Theme Primary Palette
   * @property {string} palette.primary.main Theme Primary Palette Main Color
   * @property {object} palette.secondary Theme Secondary Palette
   * @property {string} palette.secondary.main Theme Secondary Palette Main Color
   * @property {object} palette.third Theme Third Palette
   * @property {string} palette.third.backgroundColor Theme Third Palette Background Color
   * @property {string} palette.third.color Theme Third Palette Text Color
   */
  ({
    direction: locale == "ar" ? "rtl" : "ltr",
    typography: {
      fontFamily: ["Roboto", "Changa"].join(",")
    },
    palette: {
      primary: {
        main: "#2e56a5"
      },
      secondary: {
        main: "#cb4191"
      },
      third: {
        backgroundColor: GreenHue["500"],
        color: "#fff"
      },
      dark: {
        backgroundColor: GreyHue["800"],
        color: "#fff"
      },
      disabled: {
        color: "rgba(0, 0, 0, 0.26)",
        boxShadow: "none",
        backgroundColor: "rgba(0, 0, 0, 0.12)"
      }
    }
  });

export default ThemeFactory;
