// React
import React from "react";
import PropTypes from "prop-types";

// Material
import { Box, Link } from "@material-ui/core";

// Globals

// Helpers
import { LocalizedMessage } from "Helpers/Localization";
import { makeStyles } from "Helpers/Styles";

// Components

// Factories

// Screens

// Assets

// Third Parties

// Styles
const useStyles = makeStyles(theme => ({
  email: {
    direction: "ltr",
    flip: false,
    whiteSpace: "nowrap"
  },
  phone: {
    direction: "ltr",
    flip: false,
    unicodeBidi: "embed",
    whiteSpace: "nowrap"
  }
}));

// Ad-Hoc Components

/**
 * @name SupportText
 * @summary SupportText Component
 * @category Components
 * @component
 * @description
 * > Renders Sahaab support channels
 * @requires React
 * @requires PropTypes
 * @requires Box
 * @requires Link
 * @requires LocalizedMessage
 * @requires makeStyles
 * @see {@link https://reactjs.org/docs/getting-started.html|React Docs}
 * @see {@link https://material-ui.com/components/box/|Material UI Box}
 * @see {@link https://material-ui.com/components/links/|Material UI Link}
 * @see {@link LocalizedMessage|Helpers / Localization / LocalizedMessage}
 * @see {@link module:makeStyles|Helpers / Styles}
 */
const SupportText = props => {
  // Props Destructing
  const { style, issueText } = props;

  // Other Destructing
  const { REACT_APP_SUPPORT_MAIL, REACT_APP_SUPPORT_PHONE } = process.env;

  // Theme & Style Hooks
  const classes = useStyles();

  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return (
    <Box style={{ ...style }}>
      {issueText && (
        <span>
          {issueText}
          <LocalizedMessage id="components.supportText.please">
            {txt => txt + " "}
          </LocalizedMessage>
        </span>
      )}
      {REACT_APP_SUPPORT_MAIL &&
        (() => [
          <LocalizedMessage id="components.supportText.contactSupportAt">
            {txt =>
              issueText
                ? txt + " "
                : txt.charAt(0).toUpperCase() + txt.slice(1) + " "
            }
          </LocalizedMessage>,
          <Link
            key="SUPPORT_MAIL"
            href={"mailto:" + REACT_APP_SUPPORT_MAIL}
            className={classes.email}
          >
            {REACT_APP_SUPPORT_MAIL}
          </Link>,
          <LocalizedMessage id="components.supportText.or">
            {txt => " " + txt + " "}
          </LocalizedMessage>
        ])()}
      {REACT_APP_SUPPORT_PHONE &&
        (() => [
          <LocalizedMessage id="components.supportText.giveUsACall">
            {txt =>
              issueText || REACT_APP_SUPPORT_MAIL
                ? txt + " "
                : txt.charAt(0).toUpperCase() + txt.slice(1) + " "
            }
          </LocalizedMessage>,
          <Link
            key="SUPPORT_PHONE"
            href={"tel:" + REACT_APP_SUPPORT_PHONE}
            className={classes.phone}
          >
            {REACT_APP_SUPPORT_PHONE}
          </Link>
        ])()}
      .
    </Box>
  );
};

SupportText.propTypes = {
  /**
   * The issue which needs customer support
   */
  issueText: PropTypes.string,
  /**
   * Additional styling
   */
  style: PropTypes.object
};

export default SupportText;
