import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";
import MenuItem from "@material-ui/core/MenuItem";

import { LocalizedMessage } from "Helpers/Localization";

export default function AlertDialog({
  id,
  onAgree,
  deleteText,
  menuItem,
  className,
  typographyClassName,
  typographyRootClassName,
  popupState,
  ...buttonProps
}) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAgree = id => {
    onAgree(id);
    setOpen(false);
  };

  return (
    <>
      {deleteText ?
        <Tooltip title={<LocalizedMessage id="general.alertDialog.delete" />}>
          <Button
            aria-label="delete"
            color="inherit"
            onClick={handleClickOpen}
            startIcon={<DeleteIcon />}
            className={className}
          // {...buttonProps}
          >
            <Box className={typographyRootClassName}>
              <Typography
                variant="body2"
                component="h2"
                className={typographyClassName}
              >
                <LocalizedMessage id="general.alertDialog.deleteText" />
              </Typography>
            </Box>
          </Button>
        </Tooltip>
        :
        <Tooltip title={<LocalizedMessage id="general.alertDialog.delete" />}>

          <IconButton
            aria-label="delete"
            onClick={handleClickOpen}
            {...buttonProps}
          >
            <DeleteIcon />
            {deleteText ?
              <Typography
                variant="body2"
                component="h2"
                className={typographyClassName}
              >
                <LocalizedMessage id="general.alertDialog.deleteText" />
              </Typography> : null
            }
          </IconButton>
        </Tooltip>

      }
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title"><LocalizedMessage id="general.alertDialog.delete" /></DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <LocalizedMessage id="general.alertDialog.confirmMessage" />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            <LocalizedMessage id="general.alertDialog.disagree" />
          </Button>
          <Button onClick={() => handleAgree(id)} color="primary" autoFocus>
            <LocalizedMessage id="general.alertDialog.agree" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
