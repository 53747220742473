// React
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

// Material

import {
  FormControl,
  Button,
  CircularProgress,
  Divider,
  useTheme,
  Card,
  CardHeader,
  CardContent,
  CardActions,
} from "@material-ui/core"

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles"
import { LocalizedMessage } from "Helpers/Localization"
import { useForm } from "Helpers/Hooks"
import { useGlobalState } from "Helpers/GlobalState"
import {
  displaySnackbarErrors,
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
} from "Utils/displaySnackbarErrors"

// Components
import Title from "Components/Title"
import ImagePreloader from "Components/ImagePreloader"
import Box from "Components/Box"
import Typography from "Components/Typography"
import { TextField, SelectField } from "Components/FormFields"

// Factories

// Screens

// Assets

// Third Parties
import { useSnackbar } from "notistack"
import Joi from "@hapi/joi"
import { useHistory } from "react-router-dom"
import zxcvbn from "zxcvbn"

// Services
import { useEditUserMe } from "Services/User/"

// Styles
const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
    },
    formContainer: {
      flexGrow: 1,
      // padding: theme.spacing(2),
      display: "flex",
      flex: 1,
      flexDirection: "column",
      justifyContent: "center",
    },
    form: {
      display: "flex",
      flex: 1
    },
    formControl: {
      display: "flex",
      flex: 1,
    },
  }
})

// Ad-Hoc Components
const passwordValidation = (value, helpers) => {
  if (zxcvbn(value).score < 3) {
    throw new Error("password must be a strong")
  }
  return value
}
const validationSchema = Joi.object({
  firstName: Joi.string().required().min(3).max(50),
  lastName: Joi.string().required().min(3).max(50),
  password: Joi.string().custom(passwordValidation, "password validation"),
})

/**
 * @name EditProfile
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const EditProfile = ({ setHeading }) => {
  // Theme & Style Hooks
  const classes = useStyles()
  const theme = useTheme()

  // Global State Hooks
  const [accountState, setAccountState] = useGlobalState("userData.account")

  // State Hooks
  const [isScreenLoaded, setIsScreenLoaded] = useState(false)

  // Effect Hooks
  useEffect(() => {
    setFormData((fd) => {
      return {
        firstName: accountState.firstName,
        lastName: accountState.lastName,
        password: accountState.password,
      }
    })

  }, [])

  // Other Hooks
  // const [inputSkeleton, inputRef] = useSkeleton("rect");
  let history = useHistory()
  const editProfile = useEditUserMe({ languageKey: "editProfile" })
  const { enqueueSnackbar } = useSnackbar()

  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading,
    setIsLoading,
  } = useForm({ validationSchema, languageKey: "editProfile" })

  // Event Handlers
  const onCloseProfileForm = () => {
    history.replace("/portal/dashboard")
  }

  const onEditProfileHandler = () => {
    const validForm = validateForm()
    console.log(validForm)
    if (validForm) {
      setIsSubmiting(true)
      const { firstName, lastName, password } = formData
      editProfile({
        firstName,
        lastName,
        password,
      })
        .then((response) => {
          displayenqueueSnackbar(enqueueSnackbar, "edited")

          setAccountState(response.data.data)
          setIsSubmiting(false)
          onCloseProfileForm()
          setFormData({})
        })
        .catch((errors) => {
          console.log(errors)
          errors.forEach((e) => {
            if (e.field) {
              setFormErrors((fe) => ({
                ...fe,
                [e.field]: [
                  ...(typeof fe[e.field] === "array" ? fe[e.field] : []),
                  <LocalizedMessage id={e.languageKey} />,
                ],
              }))
            } else {
              enqueueSnackbar(<LocalizedMessage id={e.languageKey} />, {
                variant: "error",
                autoHideDuration: 3000,
              })
            }
          })
          setIsSubmiting(false)
        })
    }
  }

  // Other

  // Component Render
  return (
    <>
      <Box className={classes.root}>
        <Title
          pageTitle={
            <LocalizedMessage
              id="editProfile.pageTitle"
              key="editProfile.pageTitle"
            />
          }
        />

        <Card style={{ flex: 1 }} >
          <CardHeader
            title={
              <>
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h6" component="h2">
                    <LocalizedMessage id="editProfile.pageTitle" />
                  </Typography>

                </Box>
                <Divider style={{ marginTop: 10 }} />
              </>
            } />

          <CardContent style={{ display: "flex" }} >
            <form className={classes.form} noValidate>
              <FormControl className={classes.formControl}>
                <TextField
                  required
                  label={<LocalizedMessage id="editProfile.firstName" />}
                  name="firstName"
                  autoComplete="firstName"
                  tooltip={"Account first name"}
                  helpKey="Menu.Account.firstNameField"
                  autoFocus
                  fullWidth
                  // maxCharacters={50}
                  onChange={onFormFieldChange}
                  value={formData.firstName}
                  errors={formErrors.firstName}
                  isSubmitting={isSubmitting}
                  isLoading={isLoading.firstName}
                />
                <TextField
                  required
                  fullWidth
                  label={<LocalizedMessage id="editProfile.lastName" />}
                  name="lastName"
                  tooltip={"Account last name"}
                  helpKey="Menu.Account.lastNameField"
                  //maxCharacters={50}
                  onChange={onFormFieldChange}
                  value={formData.lastName}
                  errors={formErrors.lastName}
                  isSubmitting={isSubmitting}
                  isLoading={isLoading.lastName}
                />

                <TextField
                  required
                  fullWidth
                  label={<LocalizedMessage id="editProfile.password" />}
                  name="password"
                  type="password"
                  tooltip={"Account password"}
                  helpKey="Menu.Account.passwordField"
                  // maxCharacters={50}
                  onChange={onFormFieldChange}
                  value={formData.password}
                  errors={formErrors.password}
                  isSubmitting={isSubmitting}
                  isLoading={isLoading.password}
                />
              </FormControl>
            </form>
          </CardContent>

          <CardActions
            style={{
              paddingTop: "20",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button onClick={onCloseProfileForm} color="secondary">
              <LocalizedMessage id="general.cancel" />
            </Button>

            <Button
              type="button"
              color="primary"
              disabled={isSubmitting}
              onClick={onEditProfileHandler}
            >
              {isSubmitting ? (
                <CircularProgress size={20} />
              ) : (
                  <LocalizedMessage id="editProfile.edit.submit" />
                )}
            </Button>
          </CardActions>
        </Card>
      </Box >
    </>
  )
}

EditProfile.propTypes = {
  /**
   *
   */
}

EditProfile.defaultProps = {
  /**
   *
   */
}

export default EditProfile
