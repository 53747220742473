// React
import React, { useState } from "react";
import PropTypes from "prop-types";

// Material
import {
  Box,
  Typography,
  Avatar,
  Button,
  useTheme,
  CircularProgress,
} from "@material-ui/core";
import { VpnKey as VpnKeyIcon } from "@material-ui/icons";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";
import { LocalizedMessage } from "Helpers/Localization";
import { useForm } from "Helpers/Hooks";

// Components
import SupportText from "Components/SupportText";
import Title from "Components/Title";
import { TextField, PasswordField } from "Components/FormFields";

// Factories

// Screens
// Servicies
import { useResetPassword } from "Services/Auth/";

import { useForgetPassword } from "Services/Auth";
// Assets
import { UnlockAccountVector } from "Assets/Vectors";

// Third Parties
import { useSnackbar } from "notistack";
import Joi from "@hapi/joi";

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  vector: {
    width: "55%",
    alignSelf: "center",
    marginBottom: theme.spacing(1),
  },
  title: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "center",
    justifyContent: "center",

    "& $avatar": {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  resendCode: {
    margin: theme.spacing(0, 0, 2),
  },
  backToLogin: {
    margin: theme.spacing(0, 0, 2),
  },
}));

// Ad-Hoc Components
const validationSchema = Joi.object({
  code: Joi.number().required(),
  newPassword: Joi.string().required(),
  confirmNewPassword: Joi.string().required(),
});

/**
 * @name ForgetPasswordStep2
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const ForgetPasswordStep2 = (props) => {
  // Props Destructing
  const {
    className,
    backToLoginHandler,
    email,
    goToStep2Handler,
    ...otherProps
  } = props;

  // Theme & Style Hooks
  const Theme = useTheme();
  const classes = useStyles();

  // Global State Hooks

  // State Hooks
  const [isResendingEmail, setIsResendingEmail] = useState(false);

  // Effect Hooks

  // Other Hooks
  const { enqueueSnackbar } = useSnackbar();
  const postForgetPassword = useForgetPassword({
    languageKey: "auth.forget.password",
  });
  const {
    formData,
    setFormData,
    formErrors,
    setFormErrors,
    validateForm,
    onFormFieldChange,
    isSubmitting,
    setIsSubmiting,
    isLoading,
    setIsLoading,
  } = useForm({ validationSchema, languageKey: "auth.reset.password" });
  const resetPassword = useResetPassword({
    languageKey: "auth.reset.password",
  });

  // Event Handlers
  const onSubmit = (e) => {
    const validForm = validateForm();

    if (validForm) {
      const { code, newPassword, confirmNewPassword } = formData;
      setIsSubmiting(true);
      resetPassword({ email, code, newPassword })
        .then(() => {
          enqueueSnackbar(<LocalizedMessage id="auth.signIn.success" />, {
            variant: "success",
            autoHideDuration: 5000,
          });

          setIsSubmiting(false);
          setFormData({});
          window.location.href = window.location.origin + "/portal";
        })
        .catch((errors) => {
          errors.forEach((e) => {
            if (e.field) {
              setFormErrors((fe) => ({
                ...fe,
                [e.field]: [
                  ...(typeof fe[e.field] === "array" ? fe[e.field] : []),
                  <LocalizedMessage id={e.languageKey} />,
                ],
              }));
            } else {
              enqueueSnackbar(<LocalizedMessage id={e.languageKey} />, {
                variant: "error",
                autoHideDuration: 3000,
              });
            }
          });
          setIsSubmiting(false);
        });
    }
  };

  const reSend = (e) => {
    setIsResendingEmail(true);
    postForgetPassword({ email })
      .then(() => {
        enqueueSnackbar(
          <LocalizedMessage id="auth.forgetPassword.step2.resendCodeSuccess" />,
          {
            variant: "success",
            autoHideDuration: 5000,
          }
        );
        goToStep2Handler(email);
        setFormData({});
        setIsResendingEmail(false);
      })
      .catch((errors) => {
        errors.forEach((e) => {
          if (e.field) {
            setFormErrors((fe) => ({
              ...fe,
              [e.field]: [
                ...(typeof fe[e.field] === "array" ? fe[e.field] : []),
                <LocalizedMessage id={e.languageKey} />,
              ],
            }));
          } else {
            enqueueSnackbar(<LocalizedMessage id={e.languageKey} />, {
              variant: "error",
              autoHideDuration: 3000,
            });
          }
        });
        setIsSubmiting(false);
      });
  };
  // Other

  // Component Render
  return (
    <Box className={[classes.root, className].join(" ")} {...otherProps}>
      <Title
        pageTitle={
          <LocalizedMessage id="auth.forgetPassword.step2.pageTitle" />
        }
      />
      <img src={UnlockAccountVector} className={classes.vector} />
      <Box className={classes.title}>
        <Avatar className={classes.avatar}>
          <VpnKeyIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          <LocalizedMessage id="auth.forgetPassword.step2.title" />
        </Typography>
      </Box>
      <form className={classes.form} noValidate>
        <Typography variant="body2">
          <LocalizedMessage id="auth.forgetPassword.step2.subTitle" />
        </Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="code"
          label={
            <LocalizedMessage id="auth.forgetPassword.step2.verificationCode" />
          }
          name="code"
          autoComplete="code"
          onChange={onFormFieldChange}
          value={formData.code}
          errors={formErrors.code}
          isSubmitting={isSubmitting}
          isLoading={isLoading.code}
          autoFocus
        />
        <PasswordField
          required
          name="newPassword"
          label={<LocalizedMessage id="New password" />}
          InputProps={{
            autoComplete: "newPassword",
          }}
          autoComplete="newPassword"
          onChange={onFormFieldChange}
          value={formData.newPassword}
          errors={formErrors.newPassword}
          isSubmitting={isSubmitting}
          isLoading={isLoading.newPassword}
        />
        <PasswordField
          required
          name="confirmNewPassword"
          label={<LocalizedMessage id="Confirm new password" />}
          InputProps={{
            autoComplete: "confirmNewPassword",
          }}
          autoComplete="confirmNewPassword"
          onChange={onFormFieldChange}
          value={formData.confirmNewPassword}
          errors={formErrors.confirmNewPassword}
          isSubmitting={isSubmitting}
          isLoading={isLoading.confirmNewPassword}
        />
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="primary"
          className={classes.submit}
          onClick={onSubmit}
        >
          <LocalizedMessage id="auth.forgetPassword.step2.submit" />
        </Button>
        <Button
          type="button"
          fullWidth
          variant="contained"
          style={
            !isResendingEmail ? Theme.palette.third : Theme.palette.disabled
          }
          className={classes.resendCode}
          disabled={isResendingEmail}
          onClick={reSend}
        >
          {(isResendingEmail && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )) || <LocalizedMessage id="auth.forgetPassword.step2.resendCode" />}
        </Button>
        <Button
          type="button"
          fullWidth
          variant="contained"
          color="secondary"
          className={classes.backToLogin}
          onClick={backToLoginHandler}
        >
          <LocalizedMessage id="auth.forgetPassword.step2.backToLogin" />
        </Button>
        {props.children}
        <Typography variant="caption">
          <SupportText
            issueText={
              <LocalizedMessage id="auth.forgetPassword.step2.issueText"></LocalizedMessage>
            }
          />
        </Typography>
      </form>
    </Box>
  );
};

ForgetPasswordStep2.propTypes = {
  /**
   * A function that hides the ForgetPasswordForm and show SignInForm
   */
  backToLoginHandler: PropTypes.func,
};

export default ForgetPasswordStep2;
