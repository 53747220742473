// React
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

// Material
import {
  Fab,
  useTheme,
  Chip,
  Box,
  Paper,
  Table,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  IconButton,
  Tooltip,
  Divider
} from "@material-ui/core"
import EditIcon from "@material-ui/icons/Edit"
import AddIcon from "@material-ui/icons/Add"

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles"
import useStateRef from "Helpers/useStateRef"
import useSkeleton from "Helpers/useSkeleton"
import { useGlobalState } from "Helpers/GlobalState"
import { LocalizedMessage } from "Helpers/Localization"
import {
  displaySnackbarErrors,
  displaySnackbarFormErrors,
  displayenqueueSnackbar,
} from "Utils/displaySnackbarErrors"

// Components
import Title from "Components/Title"
import { TextField } from "Components/FormFields"

// Factories

// Screens
import RoleForm from "./RoleForm"

// Assets

// Third Parties
import { useSnackbar } from "notistack"

// Services
import { useDeleteRole, useAddRole, useEditRole } from "Services/Roles/"

// Styles

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
  container: {
    maxHeight: 440,
  },
}))

// Ad-Hoc Components
const columns = [
  { id: "name", label: <LocalizedMessage id="role.name" />, minWidth: 100 },
  {
    id: "isAdmin",
    label: <LocalizedMessage id="role.isAdmin" />,
    minWidth: 100,
  },
  // { id: 'permissionsMatrix', label: 'Permission Matrix', minWidth: 100 },
  { id: "actions", label: "", minWidth: 100 },
]

/**
 * @name Roles
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const Roles = ({ setHeading }) => {
  // Theme & Style Hooks
  const classes = useStyles()
  const theme = useTheme()

  // Global State Hooks
  const [roles, setRoles] = useGlobalState("sharedData.roles")

  // State Hooks
  const [searchQuery, setSearchQuery] = React.useState("")
  const [page, setPage] = React.useState(0)
  const [rowsPerPage, setRowsPerPage] = React.useState(25)
  const [rows, setRows] = useStateRef([])
  const [editId, setEditId] = React.useState("")

  /* Role Form */
  const [RoleFormOpen, setRoleFormOpen] = useState(false)
  const [RoleFormMode, setRoleFormMode] = useState("")
  const [RoleFormData, setRoleFormData] = useState({})

  // Effect Hooks

  React.useEffect(
    () =>
      setHeading(<LocalizedMessage id="role.pageTitle" key="role.pageTitle" />),
    []
  )

  useEffect(() => {
    if (roles.data) setRows(roles.data.map((el) => createData(el)))
    else setRows([])
  }, [roles])

  // Other Hooks
  const deleteRole = useDeleteRole()
  const { enqueueSnackbar } = useSnackbar()
  const addRole = useAddRole({ languageKey: "role" })
  const [inputSkeleton, inputRef] = useSkeleton("rect")
  const editRole = useEditRole({ languageKey: "role" })

  // Event Handlers
  const handleChangePage = (event, newPage) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  const handleSearchChange = (target, value) => {
    setSearchQuery(value)
    setPage(0)
  }

  const openAddRoleForm = () => {
    setRoleFormData({})
    setRoleFormMode("add")
    setRoleFormOpen(true)
  }

  const onEditRole = (role) => {
    setEditId(role.id)
    setRoleFormData(role)
    setRoleFormMode("edit")
    setRoleFormOpen(true)
  }

  const onDeleteClick = (id) => {
    deleteRole(id)
      .then(() => {
        displayenqueueSnackbar(enqueueSnackbar, "deleted")

        setRoles({ ...roles, data: roles.data.filter((row) => row.id !== id) })
      })
      .catch((errors) => {
        displaySnackbarErrors(errors, enqueueSnackbar)
      })
  }

  const onAddRoleHandler = (
    keyy,
    formData,
    validateForm,
    setFormErrors,
    setIsSubmiting,
    onCLoseRoleForm
  ) => {
    const validForm = validateForm()

    if (validForm) {
      setIsSubmiting(true)
      const { name, isAdmin, permissionsMatrix } = formData
      addRole({
        name,
        isAdmin,
        permissionsMatrix: isAdmin ? undefined : permissionsMatrix,
      })
        .then((response) => {
          displayenqueueSnackbar(enqueueSnackbar, "added")

          setRoles({
            ...roles,
            data: [...roles.data, response.data.data],
          })
          setIsSubmiting(false)
          onCLoseRoleForm()
        })
        .catch((errors) => {
          displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors)
          setIsSubmiting(false)
        })
    }
  }

  const onEditRoleHandler = (
    formData,
    validateForm,
    setFormErrors,
    setIsSubmiting,
    onCLoseRoleForm
  ) => {
    const validForm = validateForm()
    console.log(validForm)
    if (validForm) {
      setIsSubmiting(true)
      const { name, isAdmin, permissionsMatrix } = formData
      editRole({
        id: editId,
        name,
        isAdmin,
        permissionsMatrix: isAdmin ? undefined : permissionsMatrix,
      })
        .then((response) => {
          displayenqueueSnackbar(enqueueSnackbar, "edited")

          const index = roles.data.findIndex((row) => row.id === editId)
          roles.data.splice(index, 1, response.data.data)
          setRoles({ ...roles })
          setIsSubmiting(false)
          onCLoseRoleForm()
        })
        .catch((errors) => {
          console.log(errors)
          displaySnackbarFormErrors(errors, enqueueSnackbar, setFormErrors)
          setIsSubmiting(false)
        })
    }
  }
  // Other
  function createData(role) {
    return {
      id: role.id,
      name: role.name,
      permissionsMatrix: role.permissionsMatrix,
      isAdmin: role.isAdmin ? (
        <Chip label={<LocalizedMessage id="general.admin" />} color="primary" />
      ) : (
        <Chip
          label={<LocalizedMessage id="general.notAdmin" />}
          color="secondary"
        />
      ),
      actions: (
        <Box>
          <Tooltip title={<LocalizedMessage id="general.edit" />}>
            <IconButton aria-label="edit" onClick={() => onEditRole(role)}>
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Box>
      ),
    }
  }
  // Component Render
  return (
    <Box className={classes.root}>
      <Title
        pageTitle={
          <LocalizedMessage id="role.pageTitle" key="role.pageTitle" />
        }
      />

      <Paper
        dir={theme.direction}
        style={{
          padding: theme.spacing(0, 2, 2, 2),
        }}
        elevation={2}
        square
      >
        <Box
          style={{
            paddingBottom: theme.spacing(9),
            position: "relative",
          }}
        >
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" component="h2">
              <LocalizedMessage id="role.pageTitle" />
            </Typography>
            <TextField
              label={<LocalizedMessage id="general.search" />}
              size="small"
              value={searchQuery}
              onChange={handleSearchChange}
              variant="outlined"
            />
          </Box>
          <Divider style={{ margin: "0px 0px 16px" }} />

          <Tooltip title={<LocalizedMessage id="general.add" />}>
            <Fab
              disabled={roles.isFetchingRows}
              color="primary"
              aria-label="add"
              style={{
                position: "absolute",
                bottom: theme.spacing(.5),
                right: theme.spacing(1)
              }}
              onClick={openAddRoleForm}
            >
              <AddIcon />
            </Fab>
          </Tooltip>

          <Paper className={classes.root}>
            {/* Start Role Form */}

            <RoleForm
              open={RoleFormOpen}
              setOpen={setRoleFormOpen}
              mode={RoleFormMode}
              editFormData={RoleFormData}
              onAdd={onAddRoleHandler}
              onSave={onEditRoleHandler}
              full={true}
            />
            {/* End Role Form */}
            {roles.isFetchingRows && inputSkeleton ? (
              inputSkeleton
            ) : (
              <Box ref={inputRef}>
                <TableContainer className={classes.container}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ minWidth: column.minWidth }}
                          >
                            {column.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(searchQuery
                        ? rows
                          .getState()
                          .filter(
                            (row) =>
                              row.name
                                .toLowerCase()
                                .indexOf(searchQuery.toLowerCase()) != -1 ||
                              searchQuery.toLowerCase() === ""
                          )
                        : rows.getState()
                      )
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row) => {
                          return (
                            <TableRow
                              hover
                              role="checkbox"
                              tabIndex={-1}
                              key={row.code}
                            >
                              {columns.map((column) => {
                                const value = row[column.id]
                                return (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                  >
                                    {column.format
                                      ? column.format(value)
                                      : value}
                                  </TableCell>
                                )
                              })}
                            </TableRow>
                          )
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[10, 25, 100]}
                  component="div"
                  count={rows.getState().length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onChangePage={handleChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              </Box>
            )}
          </Paper>
        </Box>
      </Paper>
    </Box>
  )
}

Roles.propTypes = {
  /**
   *
   */
}

Roles.defaultProps = {
  /**
   *
   */
}

export default Roles
