// React
import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

// Material
import {
  Box,
  Checkbox,
  Paper,
  FormHelperText,
  FormControl,
  FormControlLabel,
  Typography,
  Grid,
  useTheme,
} from "@material-ui/core"

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles"
import useSkeleton from "Helpers/useSkeleton"
import { LocalizedMessage } from "Helpers/Localization"

// Components
import HelpIcon from "Components/HelpIcon"

// Factories

// Screens

// Assets

// Help

// Third Parties
import uuid from "uuid"
import _ from "lodash"
import { TrainRounded } from "@material-ui/icons"

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
  },
  helperTextWrapper: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    flex: 1,
  },
}))

// Ad-Hoc Components

/**
 * @name RolesCheckBoxes
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const RolesCheckBoxes = ({
  className,
  style,
  tooltip,
  helpKey,
  helpIconProps,
  variant,
  margin,
  autoComplete,
  inputProps,
  size,
  helperText,
  maxCharacters,
  defaultValue,
  value,
  type,
  onChange,
  id,
  isLoading,
  isSubmitting,
  hidden,
  name,
  label,
  disabled,
  errors,
  preHelperComponents,
  postHelperComponents,
  fullWidth,
  permissionsMatrix,
  ...otherProps
}) => {
  const inputFieldID = id || uuid.v4()
  const newValue = value ? JSON.parse(JSON.stringify(value)) : undefined
  const newPermissionMatrix = permissionsMatrix.data ? JSON.parse(JSON.stringify(permissionsMatrix.data)) : undefined
  value = value === undefined || value === null ? newPermissionMatrix : newValue

  // Theme & Style Hooks
  const classes = useStyles()
  const Theme = useTheme()

  // Global State Hooks

  // State Hooks
  const [currentValue, setCurrentValue] = useState(value)

  // Effect Hooks
  useEffect(() => {
    console.log(
      `%cText Field %cMount -> %cName: %c${name}`,
      "font-weight: bold; color: blue",
      "",
      "font-weight: bold;",
      ""
    )
  }, [])

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect", "500px", "100%", true)
  const [helperTextSkeleton, helperTextRef] = useSkeleton("rect")

  // Event Handlers
  const onChangeHandler = (e) => {
    typeof e.persist === "function" && e.persist()

    const [first, second] = e.target.name.split(".")
    const targetValue = e.target.value === "true"

    if (!targetValue) {
      /** This if is to make the category and item check togther */
      if (first === 'menuItem') currentValue['category']['get'] = true
      if (
        second === "edit" ||
        second === "add" ||
        second === "delete" ||
        second === "activate" ||
        second === "deactivate" ||
        second === "addPrinter" ||
        second === "generateToken"
      )
        currentValue[first]["get"] = !targetValue
    } else {
      /** This if is to make the category and item check togther */
      if (first === 'category' && currentValue['menuItem']['get']) { }
      else if (second === "get") {
        for (const key in currentValue[first]) {
          currentValue[first][key] = false
        }
      }
    }
    currentValue[first][second] = !targetValue
    /** This if is to make the category and item check togther */
    if (targetValue && first === 'category' && currentValue['menuItem']['get']) currentValue['category']['get'] = true
    setCurrentValue({ ...currentValue })

    typeof onChange === "function" && onChange(name, currentValue)
  }

  const renderPapers = (roles) => {
    return (
      roles &&
      Object.entries(roles).map(([tableName, tableValue]) => (
        <Grid
          container
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            margin: Theme.spacing(1, 0, 1, 0),
            padding: Theme.spacing(0, 0, 0, 3),
            backgroundColor: "#fafafa",
          }}
        >
          <Grid xs={7} sm={4} md={3} item>
            <Typography variant="button" key={tableName}>
              <LocalizedMessage id={`rolesNames.${tableName}`} />
            </Typography>
          </Grid>
          <Grid
            xs={5}
            sm={8}
            md={9}
            item
            style={{ display: "flex", flexWrap: "wrap" }}
          >
            {Object.entries(tableValue).map(([key, value]) => {
              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      name={tableName + "." + key}
                      // tooltip={key}
                      // helpKey="Roles.checkbox"
                      onChange={onChangeHandler}
                      value={value}
                      checked={value}
                      size="small"
                    />
                  }
                  label={<LocalizedMessage id={`rolesNames.actions.${key}`} />}
                />
              )
            })}
          </Grid>
        </Grid>
      ))
    )
  }

  // Component Render
  return (
    <Box className={className} style={{ display: hidden ? "none" : "block" }} ref={inputRef}>
      <FormControl {...{ margin }} className={classes.root} style={style}>
        {permissionsMatrix.isFetchingRows && inputSkeleton ? (
          inputSkeleton
        ) : (
            // <Box style={{ display: 'flex', flexDirection: 'column' }}>
            <Paper
              style={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              {renderPapers(currentValue)}
            </Paper>
          )}
        {/* <HelpIcon
          tooltip={tooltip}
          helpKey={helpKey}
          modalTitle={label}
          size={size}
          isLoading={isLoading}
          isSubmitting={isSubmitting}
          {...helpIconProps}
        /> */}
      </FormControl>

      <Box className={classes.root}>
        {(helperText || errors || preHelperComponents) && (
          <Box style={{ display: "flex", flexDirection: "column", flex: 1 }}>
            {preHelperComponents && <Box>{preHelperComponents}</Box>}
            <FormHelperText
              error={Boolean(errors && errors.length > 0)}
              className={classes.helperTextWrapper}
              component="div"
              style={{
                display: "flex",
                flex: 1,
              }}
            >
              <span className={classes.helperTextWrapper}>
                {helperText &&
                  !errors &&
                  (isLoading && helperTextSkeleton !== undefined ? (
                    helperTextSkeleton
                  ) : (
                      <span ref={helperTextRef}>{helperText}</span>
                    ))}
                {errors && (
                  <span style={{ display: "flex", flexDirection: "column" }}>
                    {errors.map((errorMessage, i) => (
                      <span key={i}>{errorMessage}</span>
                    ))}
                  </span>
                )}
              </span>
            </FormHelperText>
            {postHelperComponents && <Box>{postHelperComponents}</Box>}
          </Box>
        )}

        <HelpIcon
          style={{ visibility: "hidden", height: 0 }}
          tooltip={tooltip}
          helpKey={helpKey}
          modalTitle={label}
          size={size}
          {...helpIconProps}
        />
      </Box>
    </Box>
  )
}

RolesCheckBoxes.propTypes = {
  /**
   *
   */
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  //   validator: PropTypes.func,
  autoFocus: PropTypes.bool,
  tooltip: PropTypes.string,
  helpKey: PropTypes.string,
  maxCharacters: PropTypes.number,
}

RolesCheckBoxes.defaultProps = {
  variant: "outlined",
  margin: "normal",
  //   autoComplete: "off",
  onlyShowFirstError: true,
  isLoading: false,
  autoFocus: false,
  onChange: () => { },
  fullWidth: true,
}

export default RolesCheckBoxes
