// React
import React, { useState, useEffect, useRef, useMemo } from "react";
import PropTypes from "prop-types";

// Material
import { Box, FormHelperText, FormControl } from "@material-ui/core";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";

// Components
import HelpIcon from "Components/HelpIcon";
import Image from "Components/Image";
import Typography from "Components/Typography";

// Factories

// Screens

// Assets

// Help

// Third Parties
import uuid from "uuid";

// Styles
const useStyles = makeStyles((theme) => ({
  root: {},
  vectorWrapper: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  vector: {
    maxHeight: "200px",
    maxWidth: "100%",
  },
  fileInput: {
    display: "none",
  },
  allowedLabel: {
    opacity: 1.0,
    cursor: "pointer",
  },
  disabledLabel: {
    opacity: 0.6,
    cursor: "not-allowed",
  },
  label: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

// Ad-Hoc Components
function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
  return !!pattern.test(str);
}
/**
 * @name ImageField
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const ImageField = ({
  className,
  style,
  tooltip,
  placeholderImage,
  label,
  id,
  helpKey,
  helpIconProps,
  inputProps,
  onChange,
  // validator,
  // onlyShowFirstError,
  errors,
  name,
  value,
  isLoading,
  isSubmitting,
  disabled,
  ...otherProps
}) => {
  const inputFieldID = id || uuid.v4();

  // Theme & Style Hooks
  const classes = useStyles();

  // Global State Hooks

  // State Hooks
  const [displayedImage, setDisplayedImage] = useState(placeholderImage);
  // const [errors, setErrors] = useState([]);

  // Effect Hooks
  useEffect(() => {
    // if (typeof onChange === "function") onChange(fileInput.current);
    console.log(
      `%cImage Field %cMount -> %cName: %c${name}`,
      "font-weight: bold; color: green",
      "",
      "font-weight: bold;",
      ""
    );
  }, []);

  useEffect(() => {
    let image;
    if (value)
      if (validURL(value)) image = value
      else image = URL.createObjectURL(fileInput.current.files[0])

    setDisplayedImage(value ? image : placeholderImage);
  }, [value]);

  // Other Hooks
  const fileInput = useRef(null);

  // Event Handlers
  const onImageFieldChangeHandler = (e) => {
    typeof e.persist === "function" && e.persist();

    if (typeof onChange === "function")
      onChange(e.target.name, fileInput.current.files[0]);
  };

  // Other
  console.log(
    `%cImage Field %cRerender -> %cName: %c${name}`,
    "font-weight: bold; color: green",
    "",
    "font-weight: bold;",
    ""
  );

  // Component Render
  return useMemo(() => {
    console.log(
      `%cImage Field %cNew Memo -> %cName: %c${name}`,
      "font-weight: bold; color: green",
      "",
      "font-weight: bold;",
      ""
    );
    return (
      <Box className={className} {...otherProps}>
        <Box className={classes.vectorWrapper}>
          <input
            name={name}
            id={inputFieldID}
            accept="image/*"
            className={classes.fileInput}
            type="file"
            onChange={onImageFieldChangeHandler}
            disabled={isSubmitting || disabled}
            ref={fileInput}
            {...inputProps}
          />
          <Box>
            <label
              htmlFor={inputFieldID}
              className={
                isSubmitting || disabled
                  ? classes.disabledLabel
                  : classes.allowedLabel
              }
            >
              <Image
                isLoading={isLoading}
                src={displayedImage}
                className={classes.vector}
                estimatedHeight={200}
                estimatedWidth={200}
              />
            </label>
          </Box>

          {errors ? (
            <Box>
              {errors.map((errorMessage, i) => (
                <Typography variant="body2" color="error" key={i}>
                  {errorMessage}
                </Typography>
              ))}
            </Box>
          ) : (
              <Box className={classes.label}>
                <Typography
                  variant="body2"
                  data-tour="interactiveElement"
                  isLoading={isLoading}
                >
                  {label}
                </Typography>

                {/* <HelpIcon
                  tooltip={tooltip}
                  helpKey={helpKey}
                  size="small"
                  {...helpIconProps}
                  isLoading={isLoading}
                /> */}
              </Box>
            )}
        </Box>
      </Box>
    );
  }, [displayedImage, errors, isLoading, isSubmitting]);
};

ImageField.propTypes = {
  /**
   *
   */
};

ImageField.defaultProps = {
  variant: "outlined",
  margin: "normal",
  autoComplete: "off",
  onlyShowFirstError: true,
};

export default ImageField;
