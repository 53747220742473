import moment from "moment"

/**
 * @name getReportDate
 * @summary
 * @category Utils
 * @description
 * >
 */

const getReportDate = (dateDuration, dateFrom, dateTo) => {
  let from, to;
  if (dateDuration === 'custom') {
    from = moment(dateFrom).format("YYYY-MM-DD HH:mm:ss");
    to = moment(dateTo).format("YYYY-MM-DD HH:mm:ss");
  }
  else if (dateDuration === 'today') {
    from = moment().format('YYYY-MM-DD HH:mm:ss');
    to = moment().format("YYYY-MM-DD HH:mm:ss");
  }
  else if (dateDuration === 'yesterday') {
    from = moment().subtract(1, 'days').format("YYYY-MM-DD HH:mm:ss");
    to = moment().format("YYYY-MM-DD HH:mm:ss");
  }
  else if (dateDuration === 'thisWeek') {
    from = moment().startOf('week').subtract(1, 'days').format("YYYY-MM-DD HH:mm:ss");
    to = moment().endOf('week').subtract(1, 'days').format("YYYY-MM-DD HH:mm:ss");
  }
  else if (dateDuration === 'lastWeek') {
    from = moment().subtract(1, 'weeks').startOf('week').subtract(1, 'days').format("YYYY-MM-DD HH:mm:ss");
    to = moment().subtract(1, 'weeks').endOf('week').subtract(1, 'days').format("YYYY-MM-DD HH:mm:ss");
  }
  else if (dateDuration === 'thisMonth') {
    from = moment().startOf('month').format("YYYY-MM-DD HH:mm:ss");
    to = moment().endOf('month').format("YYYY-MM-DD HH:mm:ss");
  }
  else if (dateDuration === 'lastMonth') {
    from = moment().subtract(1, 'months').startOf('month').format("YYYY-MM-DD HH:mm:ss");
    to = moment().subtract(1, 'months').endOf('month').format("YYYY-MM-DD HH:mm:ss");
  }
  else if (dateDuration === 'thisYear') {
    from = moment().startOf('year').format("YYYY-MM-DD HH:mm:ss");
    to = moment().endOf('year').format("YYYY-MM-DD HH:mm:ss");
  }
  else if (dateDuration === 'lastYear') {
    from = moment().subtract(1, 'years').startOf('year').format("YYYY-MM-DD HH:mm:ss");
    to = moment().subtract(1, 'years').endOf('year').format("YYYY-MM-DD HH:mm:ss");
  }
  return { from, to };
}

export default getReportDate