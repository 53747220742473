const structure = {
  dashboard: {
    dashboard: "get",
  },

  account: {},
  role: {},
  customer: {},
  translation: {},
  generalSettings: {},
};

export default structure;
