// React
import React from "react";

// Material
import { Box } from "@material-ui/core";

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles";

// Components
import Copyrights from "Components/Copyrights";
import LanguageDropdown from "Components/LanguageDropdown";

// Factories

// Screens

// Assets

// Third Parties

// Styles
const useStyles = makeStyles(theme => ({
  footer: {
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    width: "100%",
    direction: "ltr",
    flip: false
  },
  copyrights: {
    width: "60%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-start",

    "& $copyrightsText": {
      direction: "ltr"
    }
  },
  language: {
    width: "40%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    justifySelf: "flex-end",

    "& $languageDropdown": {
      width: "27%",
      minWidth: 120,
      direction: "ltr"
    }
  }
}));

// Ad-Hoc Components

/**
 * @name AuthFooter
 * @summary Auth Footer Component
 * @category Screens / Auth
 * @component
 * @description
 * > Renders the language dropdown menu and the copyrights
 * @requires React
 * @requires Box
 * @requires makeStyles
 * @requires Copyrights
 * @requires LanguageDropdown
 * @see {@link https://reactjs.org/docs/getting-started.html|React Docs}
 * @see {@link https://material-ui.com/components/box/|Material UI Box}
 * @see {@link module:makeStyles|Helpers / Styles}
 * @see {@link Copyrights|Screens / Auth / Copyrights}
 * @see {@link LanguageDropdown|Screens / Auth / LanguageDropdown}
 */
const Footer = props => {
  // Props Destructing
  const { children, ...otherProps } = props;

  // Theme & Style Hooks
  const classes = useStyles();

  // Global State Hooks

  // State Hooks

  // Effect Hooks

  // Other Hooks

  // Event Handlers

  // Other

  // Component Render
  return (
    <Box {...otherProps}>
      <Box className={classes.footer}>
        <Box className={classes.copyrights}>
          <Copyrights className={classes.copyrightsText} />
        </Box>
        <Box className={classes.language}>
          <LanguageDropdown className={classes.languageDropdown} />
        </Box>
      </Box>
    </Box>
  );
};

Footer.propTypes = {};

export default Footer;
