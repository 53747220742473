// React
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

// Material
import {
  useTheme,
  Select as MUISelectField,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  ListItemText,
  Checkbox,
} from "@material-ui/core"
import { withStyles } from "@material-ui/styles"
import AddIcon from "@material-ui/icons/Add"
import RefreshIcon from "@material-ui/icons/Refresh"

// Globals

// Helpers
import { makeStyles } from "Helpers/Styles"
import useSkeleton from "Helpers/useSkeleton"
import { LocalizedMessage } from "Helpers/Localization"

// Components
import HelpIcon from "Components/HelpIcon"
import Box from "Components/Box";

// Factories

// Screens

// Assets

// Help

// Third Parties
import uuid from "uuid"
//import AddToSelectField from './../AddToSelectField';

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flex: 1,
    minWidth: 0,
    flexShrink: 1,
  },
  fieldWrapper: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    minWidth: 0,
    flexShrink: 1,
  },
  field: {
    flex: 1,
    minWidth: 0,
    flexShrink: 1,
  },
  formControl: {
    flex: 1,
    minWidth: 0,
    flexShrink: 1,
  },
  helperTextWrapper: {
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    flex: 1,
  },
  helperTextBoxRoot: {
    display: "flex",
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
  },
}))

const MUISelectFieldExtended = withStyles((theme) => ({
  root: {
    minWidth: 0,
    flexShrink: 1,
  },
  select: {
    minWidth: 0,
    flexShrink: 1,
  },
  filled: {
    minWidth: 0,
    flexShrink: 1,
  },
  outlined: {
    minWidth: 0,
    flexShrink: 1,
  },
}))(MUISelectField)

// Ad-Hoc Components

/**
 * @name SelectField
 * @summary
 * @category
 * @component
 * @description
 * >
 */
const SelectField = ({
  className,
  style,
  tooltip,
  helpKey,
  variant,
  margin,
  autoComplete,
  helpIconProps,
  options,
  getOptionLabel,
  getOptionSelected,
  defaultValue,
  onChange,
  helperText,
  size,
  inputProps,
  openForm,
  refresh,
  isLoading,
  hidden,
  isSubmitting,
  disabled,
  setDefaultValue,
  label,
  id,
  required,
  name,
  value,
  multiple,
  errors,
  preHelperComponents,
  postHelperComponents,
  fullWidth,
  addPermission,
  neglectNone,
  ...otherProps
}) => {
  let currentValue
  if (value) {
    if (multiple) {
      currentValue = value
        .map((v) => options.find((option) => getOptionSelected(option) === v))
        .filter((v) => v !== undefined)
    } else {
      currentValue = options.find(
        (option) => getOptionSelected(option) === value
      )
      currentValue = currentValue || { text: "", value: "" }
    }
  } else if (defaultValue) {
    if (multiple) {
      currentValue = defaultValue
        .map((v) => options.find((option) => getOptionSelected(option) === v))
        .filter((v) => v !== undefined)
    } else {
      currentValue = options.find(
        (option) => getOptionSelected(option) === defaultValue
      )
      currentValue = currentValue || { text: "", value: "" }
    }
  } else {
    if (multiple) {
      currentValue = []
    } else {
      currentValue = { text: "", value: "" }
    }
  }
  // Theme & Style Hooks
  const classes = useStyles()
  const theme = useTheme()

  // Global State Hooks

  // State Hooks
  const [inputFieldID] = useState(id || uuid.v4())

  // Effect Hooks
  useEffect(() => {
    onChangeHandler({
      persist: () => { },
      target: {
        name,
        value: multiple ? currentValue.map((v) => v.text) : currentValue.text,
      },
    })
    console.log(
      `%cSelect %cField Mount -> %cName: %c${name}`,
      "font-weight: bold; color: burlywood",
      "",
      "font-weight: bold;",
      ""
    )
  }, [])

  // Other Hooks
  const [inputSkeleton, inputRef] = useSkeleton("rect")
  const [helperTextSkeleton, helperTextRef] = useSkeleton("rect")

  // Event Handlers
  const onChangeHandler = (e) => {
    if (typeof onChange == "function") {
      if (!e.target.value || e.target.value.includes("Buttons")) {
      } else {
        if (e.target.value.includes("none")) e.target.value = multiple ? [] : ""
        else {
          let o
          if (multiple) {
            o = e.target.value.map((id) =>
              options.find((option) => getOptionLabel(option) === id)
            )
          } else {
            o = options.find(
              (option) => getOptionLabel(option) === e.target.value
            )
          }

          e.target.value = o
            ? multiple
              ? o.map((oo) => oo.value)
              : o.value
            : multiple
              ? []
              : ""
        }

        onChange(e.target.name, e.target.value)
      }
    }
  }

  // Other
  console.log(
    `%cSelect %cRerender -> %cName: %c${name}`,
    "font-weight: bold; color: burlywood",
    "",
    "font-weight: bold;",
    ""
  )

  // Component Render
  return (
    <Box className={className}>
      <Box className={classes.root} style={style}>
        <FormControl
          variant={variant}
          className={classes.formControl}
          margin={margin}
          size={size}
        >
          <Box className={classes.fieldWrapper}>
            {isLoading && currentValue && inputSkeleton ? (
              inputSkeleton
            ) : (
                <>
                  <InputLabel
                    variant={variant}
                    id={`${inputFieldID}-label`}
                    htmlFor={inputFieldID}
                    required={required}
                  >
                    {label}
                  </InputLabel>
                  <MUISelectFieldExtended
                    {...{
                      ref: inputRef,
                      labelId: `${inputFieldID}-label`,
                      onChange: onChangeHandler,
                      error: Boolean(errors && errors.length > 0),
                      inputProps: {
                        autoComplete,
                        disabled: isSubmitting || disabled,
                        id: inputFieldID,
                        ...inputProps,
                      },
                      disabled: isSubmitting || disabled,
                      variant: variant,
                      className: classes.field,
                      id: inputFieldID,
                      required: required,
                      label,
                      name,
                      value: multiple
                        ? currentValue.map((v) => v.text)
                        : currentValue.text,
                      multiple,
                      renderValue:
                        multiple && ((selected) => selected.join(", ")),
                      ...otherProps,
                    }}
                  >
                    {!neglectNone &&
                      <MenuItem
                        style={{ direction: theme.direction }}
                        value="none"
                        key="None"
                      >
                        <em>
                          <LocalizedMessage id="general.none" />
                        </em>
                      </MenuItem>
                    }
                    {options.map((option, i) => {
                      return multiple ? (
                        <MenuItem
                          style={{ direction: theme.direction }}
                          value={getOptionLabel(option)}
                          key={option.value}
                          disabled={option.disabled}
                        >
                          <Checkbox
                            checked={Boolean(
                              currentValue.find((cv) => cv.value === option.value)
                            )}
                          />
                          <ListItemText primary={getOptionLabel(option)} />
                        </MenuItem>
                      ) : (
                          <MenuItem
                            style={{ direction: theme.direction }}
                            value={getOptionLabel(option)}
                            key={i}
                            disabled={option.disabled}
                          >
                            {getOptionLabel(option)}
                          </MenuItem>
                        )
                    })}

                    {openForm && addPermission && (
                      <>
                        <MenuItem
                          style={{ direction: theme.direction }}
                          value="Buttons"
                          key="None"
                          onClick={openForm}
                        >
                          <AddIcon style={{ margin: 5 }} />
                          <LocalizedMessage id="general.dropdown.add" />
                        </MenuItem>
                        {/* <MenuItem value="Buttons" key="None" onClick={refresh}>
                                <RefreshIcon style={{ margin: 5 }} />
                                <LocalizedMessage id="general.dropdown.refresh" />
                              </MenuItem> 
                          */}
                      </>
                    )}
                  </MUISelectFieldExtended>
                </>
              )}
            {/* <HelpIcon
              {...{
                tooltip,
                helpKey,
                size,
                isLoading,
                modalTitle: label,
                ...helpIconProps,
              }}
            /> */}
          </Box>
          <Box className={classes.helperTextBoxRoot}>
            {(helperText || errors || preHelperComponents) && (
              <Box
                style={{ display: "flex", flexDirection: "column", flex: 1 }}
              >
                {preHelperComponents && <Box>{preHelperComponents}</Box>}
                <FormHelperText
                  error={Boolean(errors && errors.length > 0)}
                  className={classes.helperTextWrapper}
                  component="div"
                  style={{
                    display: "flex",
                    flex: 1,
                  }}
                >
                  <span className={classes.helperTextWrapper}>
                    {helperText &&
                      !errors &&
                      (isLoading && helperTextSkeleton !== undefined ? (
                        helperTextSkeleton
                      ) : (
                          <span ref={helperTextRef}>{helperText}</span>
                        ))}
                    {errors && (
                      <span
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        {errors.map((errorMessage, i) => (
                          <span key={i}>{errorMessage}</span>
                        ))}
                      </span>
                    )}
                  </span>
                </FormHelperText>
                {postHelperComponents && <Box>{postHelperComponents}</Box>}
              </Box>
            )}
            <HelpIcon
              style={{ visibility: "hidden", height: 0 }}
              tooltip={tooltip}
              helpKey={helpKey}
              modalTitle={label}
              size={size}
              {...helpIconProps}
            />
          </Box>
        </FormControl>
      </Box>
    </Box>
  )
}

SelectField.propTypes = {
  /**
   *
   */
}

SelectField.defaultProps = {
  options: Array(),
  variant: "outlined",
  margin: "normal",
  helperText: "",
  autoComplete: "off",
  multiple: false,
  getOptionLabel: (option) => option.text,
  getOptionSelected: (option) => option.value,
}

export default SelectField
